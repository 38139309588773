import LocalizedStrings from 'react-localization';

export let browserErrorMessages = new LocalizedStrings({

    english: {
        default: "Please fill out this field",
        wrong_format: "Please match the requested format",
        not_selected: "Please select an item in this list"
    },
    french: {
        default: "Veuillez remplir ce champ",
        wrong_format: "Veuillez respecter le format demandé",
        not_selected: "Veuillez choisir un élément dans cette liste "
    }
});