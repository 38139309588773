import React from "react";
import regeneratorRuntime from "regenerator-runtime";

import SiteContext from "~/src/context/Site";
import localization from "~/src/static/localization/authentication"
import FormField from "../common/FormField"
import {postToken} from "~/src/REST"

const Enabled = () => {
    const {setAuthToken,language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    const [id,setID] = React.useState("")
    const [email,setEmail] = React.useState("")

    const handleSubmit = async (event) => {
        event.preventDefault()
        let tempToken = await postToken({id,email})
        setAuthToken(tempToken)
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormField  label={localization.id}
                        onChange={({target:{value}}) => setID(value)}
                        type="text" formData={{id}}
                        name="id" />
            <FormField  label={localization.email}
                        onChange={({target:{value}}) => setEmail(value)}
                        type="email"
                        formData={{email}}
                        name="email" />
            <input type="submit" value="Submit" />
        </form>
    );
}

export default Enabled