import SiteContext from "~/src/context/Site";
import DocumentContext from "~/src/context/Document";
import axios from "axios";
import React from "react";
import regeneratorRuntime from "regenerator-runtime";

const defaultLineItems = [
    {
        amount: 19.99,
        description: "",
        title: "Protection Coverage",
        type: "protection",
    },
    {
        amount: -19.99,
        description: "(4 weeks free)",
        title: "Promotion",
        type: "protection_first_month_discount",
    }
]

export const useProtectionPlan = () => {
    const { facilityData } = React.useContext(SiteContext);
    const { contact_data: contactData } = React.useContext(DocumentContext);
    const [lineItems,setLineItems] = React.useState(defaultLineItems)

    //TODO: Memoize this so we don't keep on hitting sitelink
    React.useEffect(async ()=>{
        if (!facilityData.id) return;
        if (!contactData.additional_information.stored_items_type) return;
        const {data: insuranceCoverages} = await axios.get(`${process.env.SITELINK_MICROSERVICE_URL}/insurance/${facilityData.id}`)

        const itemType = contactData.additional_information.stored_items_type
        const filterString = itemType === "commerical"? "Com. Protection Plan" : "Res. Protection Plan";
        const insuranceCoverage = insuranceCoverages.filter(({description})=>description===filterString).pop();

        const lineItems = [
            {
                amount: parseFloat(insuranceCoverage.premium),
                description: "",
                title: "Protection Coverage",
                type: "protection",
            },
            {
                amount: -1 * parseFloat(insuranceCoverage.premium),
                description: "(4 weeks free)",
                title: "Promotion",
                type: "protection_first_month_discount",
            }
        ]
        setLineItems(lineItems)
    },[facilityData.id,contactData.additional_information.stored_items_type])

    return lineItems
}
