import React from "react";
import { useHistory } from "react-router";
import regeneratorRuntime from "regenerator-runtime";
import styled from "styled-components";
import DocumentContext from '~/src/context/Document'
import SiteContext from '~/src/context/Site'
import localization from "~/src/static/localization/reservation/main"

import ClientInformation from "./ClientInformation";
import NextButton from '~/src/components/common/NextButton'
import Acknowledgement from "~/src/components/common/Acknowledgement"
import AdditionalInformation from "./AdditionalInformation";
import {getPricing,postReservationContact} from "~/src/REST"
import MoveInDate from "./moveInDate/Main";
import alert from "~/src/lib/alert"
import { useFacility } from "../../../hooks";

const H1Style = styled.h1`
    color:#666;
    font-weight:bold;
    font-size: 1.5rem;
    margin: 20px 0;
`;
const AlignRightStyle =styled.div`
    display:flex;
    justify-content:flex-end;
`;

const ContactInformation = () => {
    const {quote, contact_data,setContact_data,setPricing,setContactBreadCrumbs} = React.useContext(DocumentContext)
    const {language,authToken, facilityData} = React.useContext(SiteContext)
    const [acknowledgementSigned,setAcknowledgementSigned] = React.useState(false)
    const [formSubmitted,setFormSubmitted] = React.useState(false)
    const [processingSubmission,setProcessingSubmission] = React.useState(false)
    const { validate } = useFacility()
    const history = useHistory();
    localization.setLanguage(language)
    let labels = localization.client_information

    const facilityName = facilityData? `${facilityData.brand} - ${facilityData.name}`:"facility_name"

    const handleChange = (key,value) => {
        setContact_data({...contact_data,[key]:value})
        setAcknowledgementSigned(false) //user will need to sign the acknowledgements again
    }

    const addBreadCrumbs = (breadcrumbs) => {
        setContactBreadCrumbs({...breadcrumbs,contract_type:"contact_information_form"})
    }

    const onNextSection = async (event) => {
        event.preventDefault()
        contact_data.client_information.first_name = contact_data.client_information.first_name.trim();
        contact_data.client_information.last_name = contact_data.client_information.last_name.trim();
        if (acknowledgementSigned) {
            if (!formSubmitted){
                //Validate the move_in_date
                if(!(validate(contact_data.client_information.move_in_date,quote.number_of_available_units))) return

                setProcessingSubmission(true)
                setFormSubmitted(true)
                await postReservationContact(contact_data,authToken)
                setContact_data({...contact_data})

                //Get actual pricing as the previous pricing displayed is just an estimate
                let pricing = await getPricing({authToken})
                setPricing(pricing)

                alert({
                    title:labels.success_message.contract_signed.title,
                    message:labels.success_message.contract_signed.message,
                    type:labels.success_message.contract_signed.type,
                })
                setProcessingSubmission(false)
            }
            history.push("/reservation/payment_information")
        }else alert({
            title:labels.error_message.acknowledgement_missing.title,
            message:labels.error_message.acknowledgement_missing.message,
        })
    }

    return (
            <form onSubmit={onNextSection} className="container">
                <H1Style>{labels.title}</H1Style>
                <MoveInDate onChange={handleChange} data={contact_data}/>
                <ClientInformation onChange={handleChange} data={contact_data}/>
                <AdditionalInformation onChange={handleChange} data={contact_data}/>
                <Acknowledgement    title={"Contact Information"}
                                    text={localization.formatString(
                                        labels.acknowledgement,
                                        {
                                            first_name:contact_data.client_information.first_name,
                                            last_name:contact_data.client_information.last_name,
                                            store_name:facilityName,
                                        }
                                    )}
                                    signed={acknowledgementSigned}
                                    setSigned={setAcknowledgementSigned}
                                    onClick={addBreadCrumbs}/>
                <AlignRightStyle>
                    <NextButton style={{float: 'right'}} isLoading={processingSubmission}/>
                </AlignRightStyle>
            </form>
    )
}

export default ContactInformation