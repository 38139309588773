import regeneratorRuntime from "regenerator-runtime";
import {buildConfig} from "./lib"
import Axios from "axios"
if (!process.env.ERO_BACKEND_URL) throw "Environment Variable 'ERO_BACKEND_URL' does not exist or is undefined"

export const postIdentityVerification = async ({authToken,language}) => {
    let config = buildConfig(authToken,{
        language
    });
    let url = `${process.env.ERO_BACKEND_URL}/identity_verification/new`;
    let response = await Axios.post(url,null,config);
    return response.data;
}