import React from "react";
import '~/src/static/styles/_base.scss'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import regeneratorRuntime from "regenerator-runtime";

import Reservation from './reservation/Main';
import IdentityVerification from './identityVerification/Main';
import Landing from "./landing/Main";
import Header from './Header'
import Page404 from './Page404'
import Authentication from "./authentication/Main";
import Intialization from "./Intialization"

import SiteContext from '~/src/context/Site'
import DocumentContext from '~/src/context/Document'

import contactFieldsInitial from '~/src/static/initial_fields/contact'
import paymentFieldsInitial from '~/src/static/initial_fields/payment'
import terms_and_condtionsInitial from "~/src/static/initial_fields/terms_and_conditions"

import getBrandInfo from "~/src/lib/getBrandInfo"
import { ErrorBoundary } from "./common/ErrorBoundary";

const Webpage = () => {
    //Set up global context with state management
    const [contact_data, setContact_data] = React.useState(contactFieldsInitial);
    const [payment_data, setPayment_data] = React.useState(paymentFieldsInitial);
    const [terms_and_conditions, setTerms_and_conditions] = React.useState(terms_and_condtionsInitial);
    const [pricing, setPricing] = React.useState()
    const [quote,setQuote] = React.useState();
    const [quote_id,setQuote_id] = React.useState();
    const [authToken, setAuthToken] = React.useState("");
    const [language, setLanguage] = React.useState("english")
    const [facilityData, setFacilityData] = React.useState()
    const [brandInfo,setBrandInfo] = React.useState(getBrandInfo("as"));
    const [isMoveInSuccessful,setIsMoveInSuccessful] = React.useState(null)
    const [redirectedToConfirmation,setRedirectedToConfirmation] = React.useState(null)
    const [identityVerificationCompleted,setIdentityVerificationCompleted] = React.useState(null)
    const [contactBreadCrumbs,setContactBreadCrumbs] = React.useState({})

    return (
        <DocumentContext.Provider value={{
            contact_data,setContact_data,
            payment_data,setPayment_data,
            terms_and_conditions,setTerms_and_conditions,
            quote,setQuote,
            quote_id,setQuote_id,
            pricing,setPricing,
            contactBreadCrumbs,setContactBreadCrumbs,
        }}>
        <SiteContext.Provider value={{
            authToken,setAuthToken,
            language,setLanguage,
            facilityData,setFacilityData,
            brandInfo,setBrandInfo,
            isMoveInSuccessful,setIsMoveInSuccessful,
            redirectedToConfirmation,setRedirectedToConfirmation,
            identityVerificationCompleted,setIdentityVerificationCompleted,
        }}>
            <ReactNotification />
            <Authentication/>
            <Router>
                <Intialization/> 
                <Header/>
                <ErrorBoundary>
                    <Switch>
                        <Route path="/" exact component={Landing}/>
                        <Route path="/reservation" component={Reservation}/>
                        <Route path="/identity_verification" component={IdentityVerification}/>
                        <Route component={Page404}/>
                    </Switch>
                </ErrorBoundary>
            </Router>
        </SiteContext.Provider>
        </DocumentContext.Provider>
    )
}

export default Webpage