import regeneratorRuntime from "regenerator-runtime";
import alert from "~/src/lib/alert"
import {postContract} from "~src/REST"
import localization from "~/src/static/localization/contracts/index";

const recordContract = async ({
        breadcrumbs,
        contract_type,
        contract_version,
        contract_name,
        language,
        authToken
    }) => {
    localization.setLanguage(language);

    let id = await postContract({
        contractDetails: {
            breadcrumbs,
            contract_version,
            contract_type,
        },
        authToken,
    })
    alert({
        title:localization.message.success.title,
        message:localization.formatString(localization.message.success.message, {
            formName:contract_name
        }),
        type:localization.message.success.type
    })
    return id
};

export default recordContract