import React from "react"
import RentOnline from "./RentOnline"
import localization from "~/src/static/localization/landing/introduction"
import SiteContext from "~/src/context/Site"
import DocumentContext from "~/src/context/Document"
import {IntroductionStyle} from "./styles"
import {selectQuoteTypeLocalization,getInfoString,getCallBackPrompt} from "./lib"

const TempData = {
    name: "John Doe",
    unit_size: "10' x 10'"
}

export const Introduction = () => {
    const {language,facilityData} = React.useContext(SiteContext)
    const {quote} = React.useContext(DocumentContext)

    const local = selectQuoteTypeLocalization(quote,language)
    const info = getInfoString(local,quote,facilityData)
    const header = localization.formatString(local.header,{
        name:quote && quote.first_name || TempData.name
    })

    return (
        <IntroductionStyle>
            <div className="container">
                <h1>{header}</h1>
                <p>{info}</p>
                {getCallBackPrompt(quote,language)}
                <RentOnline localization={local}/>
            </div>
        </IntroductionStyle>
    )
}