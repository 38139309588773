import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        fine_print: `   *Minimum four week rental. Offers are valid for new clients only,
                        not applicable to unit transfers, may not be combined and are based on availability.
                        Prices may be different due to rounding and unit sizes are approximate.
                        This offer cannot be combined with affinity programs or event promotions.`,
    },
    french: {
        fine_print: `   *Minimum de quatre semaines de location. Les offres s’appliquent aux nouveaux clients seulement. Elles ne sont pas valides sur les transferts d'unités, ne peuvent être jumelées et sont offertes en fonction de la disponibilité. Les prix peuvent varier en raison de valeurs arrondies et les tailles des unités sont approximatives. Cette offre ne peut être jumelée aux programmes d’affinité ou aux promotions d'événements.`
    }
});

export default LOCALIZATION