import React from "react";
import regeneratorRuntime from "regenerator-runtime";
import {useHistory} from "react-router";

import alert from '~/src/lib/alert'
import CardInformation from "./CardInformation";
import LineItems from "./LineItems/Main";
import NextButton from '~/src/components/common/NextButton';
import Indicator from '~/src/components/common/assets/Indicator';
import DocumentContext from '~/src/context/Document'
import SiteContext from '~/src/context/Site'
import Acknowledgement from "~/src/components/common/Acknowledgement"
import localization from "~/src/static/localization/reservation/main"
import Store from "~/src/components/common/Store"
import recordContract from "~/src/lib/recordContract"
import styled from "styled-components"
import {putPricingProtectionPlan} from "~/src/REST";
import {analytics} from "~/src/lib/analytics"
import { postContractHTML } from "../../../REST";

const H1Style = styled.h1`
    color:#666;
    font-weight:bold;
    font-size: 1.5rem;
    margin: 20px 0 0 0;
`;
const Main = () => {
    const {contact_data, payment_data, setPayment_data, pricing, contactBreadCrumbs, terms_and_conditions, setTerms_and_conditions} = React.useContext(DocumentContext)
    const {language, authToken} = React.useContext(SiteContext)
    const [acknowledgementSigned, setAcknowledgementSigned] = React.useState(false)
    const [paymentBreadCrumbs, setPaymentBreadCrumbs] = React.useState({})
    localization.setLanguage(language)
    let labels = localization.payment_information

    const addBreadCrumbs = (breadcrumbs) => {
        setPaymentBreadCrumbs({...breadcrumbs,contract_type:"payment_information_form"})
    }

    const history = useHistory();

    const handleChange = (key, value) => {
        setPayment_data({...payment_data, [key]: value})
        setAcknowledgementSigned(false) //user will need to sign the acknowledgements again
    }

    const handleChangeAcknowledgement = (state = false) => {
        setAcknowledgementSigned(false) //user will need to sign the acknowledgements again
    }

    const onNextSection = async (event) => {
        event.preventDefault()
        //If protection is unapplied, make sure that there is a reason
        let {protection_plan} = pricing
        if (!protection_plan.applied && !protection_plan.exemption.chosen_reason){
            let {title,message} = labels.error_message.protection_declined
            alert({title,message})
            return
        }

        if (acknowledgementSigned) {
            //Submit an edit to the protection plan by copying over data we have here
            putPricingProtectionPlan(authToken,pricing.protection_plan)

            //Get the client information form contract details
            const contract_type = "client_information"
            let {contract_version,recommended_contract_title} = await postContractHTML({
                contract_type,
                language,
                authToken,
                exclude_html:true,
            })

            //Sign the acknowledgement
            let id = await recordContract({
                breadcrumbs: [contactBreadCrumbs,paymentBreadCrumbs],
                contract_type,
                contract_version,
                contract_name: recommended_contract_title,
                language,
                authToken
            })
            setTerms_and_conditions([...terms_and_conditions,id])

            history.push("/reservation/sign_contracts")

            analytics.event({
                category: "Add Payment Method",
                action: "Click",
                label: "Next",
            })
        }
        else {
            let {title,message} = labels.error_message.acknowledgement_missing
            alert({title,message})
        }
    }

    const onPrevSection = () => {
        history.push("/reservation/contact_information")
    }

    return (
        <form onSubmit={onNextSection} className="container">
            <H1Style>{labels.title}</H1Style>
            <Store/>
            <LineItems onChangeAcknowledgement={handleChangeAcknowledgement} show_asterisk={false}/>
            <CardInformation onChange={handleChange} data={payment_data}/>
            <Acknowledgement title={"Payment Authorization"}
                             text={localization.formatString(
                                 localization.payment_information.acknowledgement,
                                 {
                                     first_name: contact_data.client_information.first_name,
                                     last_name: contact_data.client_information.last_name,
                                 }
                             )}
                             signed={acknowledgementSigned}
                             setSigned={setAcknowledgementSigned}
                             onClick={addBreadCrumbs}/>
            <Indicator>
                <NextButton type="button" bgcolor="#AFAFAF" direction={false} onClick={onPrevSection}/>
                <NextButton/>
            </Indicator>
        </form>
    )
}

export default Main