import React from "react";
import styled from "styled-components";
import SiteContext from "~/src/context/Site"
import localization from "~/src/static/localization/common/rental_details/line_items"
import Reason from "./Reason"
import DocumentContext from "~/src/context/Document"


const PopupRejectedStyle = styled.div`
    position: absolute;
    left: 50%;
    top: -135px;
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 1em;
    min-width: 120px;
    max-width: 100%;
    color: #666;
    font-size: 16px;
    background: #FFF;
    border: solid 1px rgba(112,112,112,0.3);
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
    @media (max-width: 768px) {
        display: block;
        position: relative;
        left: 0;
        top: 0;
        margin: 15px 0 25px 0;
    }

    :before {
        content: "";
        position: absolute;
        top: 145px;
        left: -23px;
        margin-top: -12px;
        border: 12px solid transparent;
        border-right: 12px solid #FFF;
        z-index: 2;
        @media (max-width: 768px) {
            top: -11px;
            left: 45px;
            border-bottom: 12px solid #FFF;
            border-right: 12px solid transparent;
        }
    }
    :after {
        content: "";
        position: absolute;
        top: 145px;
        left: -27px;
        margin-top: -13px;
        border: 13px solid transparent;
        border-right: 14px solid rgba(112,112,112,0.3);
        z-index: 1;
        @media (max-width: 768px) {
            top: -13px;
            left: 44px;
            border-bottom: 12px solid rgba(112,112,112,0.3);
            border-right: 12px solid transparent;
        }
    }
    p {
        margin: 0;
        padding: 0 0 1em;
    }
    p:last-of-type {
        padding: 0;
    }
    label {
        padding-top:0.5em;
    }
    input[type="radio"] {
        margin-right:10px;
    }
    input[type="text"] {
        margin-left:10px;
    }
`;

const PopUpRejected = ({ onChangeAcknowledgement }) => {
    const {contact_data,pricing,setPricing} = React.useContext(DocumentContext)
    let {stored_items_type} = contact_data.additional_information
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    //Get the apporiate reasons
    let rejectedLabels
    if (stored_items_type === "vehicle"){
        rejectedLabels = localization.rejected.vehicle
    }else{
        rejectedLabels = localization.rejected.standard
    }
    let reasons = rejectedLabels.reasons

    //Every time the localization changes, reset the reasons to its defaults
    React.useEffect(() => {
        let new_pricing = {...pricing}
        //Reset the available_reasons
        new_pricing.protection_plan.exemption.available_reasons = reasons
        //Set the default reason
        new_pricing.protection_plan.exemption.chosen_reason = reasons[0]
        setPricing(new_pricing)
    },[localization,language])

    const Reasons = reasons.map((reason, index) => {
        return (
            <Reason key={index} text={reason} onChangeAcknowledgement={onChangeAcknowledgement}/>
        )
    })

    return (
        <PopupRejectedStyle className="balloon-left">
            <div className="radio-buttons">
                <p>{rejectedLabels.message}</p>
                <p>{rejectedLabels.prompt}
                    {Reasons}
                </p>
                { rejectedLabels.other && <Reason text="Other" label={rejectedLabels.other} onChangeAcknowledgement={onChangeAcknowledgement}/>}
            </div>
        </PopupRejectedStyle>
    )
}

export default PopUpRejected