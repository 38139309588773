import LocalizedStrings from 'react-localization';

export const localization = new LocalizedStrings({
    english: {
        header: "Opps",
        messages: [
            "An error occurred. We apologize for inconvenience.",
            "In a few minutes you will receive a call from a friendly Storage Advisor to review the details of your reservation and provide an overview of the move-in process.",
            "We look forward to connecting with you.",
        ],
    },
    french: {
        header: "French Required",
        messages: [
            "French Required",
            "French Required",
        ],
    }
});