import React from "react";
import styled from "styled-components";
import FormField from "~/src/components/common/FormField";
import SiteContext from '~/src/context/Site';
import localization from "~/src/static/localization/reservation/main";


const PersonalDetailsForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em;
    grid-template-areas:
        ". . ."
        ". . ."
        ". . .";
    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: none;
    }
    @media (max-width: 575px) {
        display: block;
    }
`;

const HideInMobile = styled.div`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

const DoubleField = styled.div`
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr;
`;

const ClientInformation = ({onChange,data}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    let labels = localization.client_information.information
    const formData = data? data["client_information"]:{};
    const handleChange = event => {
      const {value,name} = event.target;
      onChange("client_information",{...formData,[name]:value})
    };

    return (
        <PersonalDetailsForm>
            <FormField label={labels.first_name}        required={true} onChange={handleChange} type="text" formData={formData} name="first_name" info={labels.info.first_name}/>
            <FormField label={labels.last_name}         required={true} onChange={handleChange} type="text" formData={formData} name="last_name" info={labels.info.last_name}/>
            <HideInMobile />
            <FormField label={labels.street_address}    required={true} onChange={handleChange} type="text" formData={formData} name="street_address"/>
            <FormField label={labels.city}              required={true} onChange={handleChange} type="text" formData={formData} name="city"/>
            <DoubleField>
                <FormField label={labels.province}      required={true} onChange={handleChange} type="province" formData={formData} name="province"/>
                <FormField label={labels.postal_code}   custom={{placeholder:"___ ___"}}
                                                        required={true} onChange={handleChange} type="postal_code" formData={formData} name="postal_code"/>
            </DoubleField>
            <FormField label={labels.phone.primary}     required={true} onChange={handleChange} type="tel"  formData={formData} name="phone_number"/>
            <FormField label={labels.phone.alternate}                   onChange={handleChange} type="tel"  formData={formData} name="alt_phone_number" />
            <FormField label={labels.email}             required={true} onChange={handleChange} type="email"formData={formData} name="email"/>
        </PersonalDetailsForm>
    )
}

export default ClientInformation