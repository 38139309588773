import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        header: `What happens next?`,
        step_1:`You will receive a call from one of our friendly Storage Advisors to confirm the details of your rental and provide your access code.`,
        step_2:`With the access code, you are able to move-in at your convenience and start using your storage unit.`,
        step_3:`Moving boxes and packing supplies can be {0} or over the telephone
                to be delivered contact-free to your storage unit or for curbside pickup.`,
        step_3_hyperlink_text:`ordered online`,
    },
    french: {
        header: `Que se passe-t-il ensuite?`,
        step_1:`Vous recevrez un appel de l'un de nos sympathiques conseillers en entreposage qui confirmera les détails de votre location et vous fournira un code d'accès.`,
        step_2:`Une fois que vous obtenez le code d’accès, vous pouvez emménager à votre convenance et commencer à utiliser votre unité d’entreposage.`,
        step_3:`Des boîtes de déménagement et des fournitures d'emballage peuvent être {0} ou par téléphone et être livrées sans contact à votre unité d’entreposage ou être ramassées à l’auto.`,
        step_3_hyperlink_text:`commandées en ligne `,
    }
});

export default LOCALIZATION