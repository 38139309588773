const titles = {
    english: {
        section_1:{
            tab: "Add Client Information",
            title: "Enter Contact Information",
            info: "Reserve your unit today with just a few peices of information.",
        },
        section_2:{
            tab: "Add Payment Method",
            title: "Add Payment Method",
        },
        section_3:{
            tab: "E-sign Agreement",
            title: "Complete e-sign and Payment",
        },
        section_4:{
            tab: "Move-in",
            title: "Complete e-sign and Payment",
        }
    },
    french:{
        section_1:{
            tab: "Renseignements sur le client",
            title: "Entrez vos coordonnées",
            info: "",
        },
        section_2:{
            tab: "Mode de paiement",
            title: "Ajoutez un mode de paiement",
        },
        section_3:{
            tab: "Signature électronique de l’entente",
            title: "Procédez à la signature électronique du contrat",
        },
        section_4:{
            tab: "Emménagement",
            title: "Complete e-sign and Payment",
        }

    },
}

export default titles