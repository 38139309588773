import LocalizedStrings from 'react-localization';
import messages from "./messages"
import contract_name from "./contract_name"

let LOCALIZATION = new LocalizedStrings({
    english:{
        message:messages.english,
        contract_name:contract_name.english
    },
    french: {
        message:messages.french,
        contract_name:contract_name.french
    }
});

export default LOCALIZATION