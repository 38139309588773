const additional_contacts = {
    english: {
        company_name:`Company Name`,
        add_company:`Add company name to the account`,
        signing_officer:`Are you the signing officer or owner?`,
        not_officer_disclaimer:`Note: Additional paperwork is required by the company’s signing officer or owner. Our store team will follow up with you.`,
        yes:`Yes`,
        no:`No`,
        approx_value:`Approximate value of items stored`,
        stored_items_type:`Type of items stored`,
        commercial:`Commercial`,
        residential:`Residential`,
        vehicle:`Vehicle`,
    },
    french:{
        company_name:`Nom de l’entreprise`,
        add_company:`Ajouter le nom de l’entreprise au compte`,
        signing_officer:`Êtes-vous le signataire autorisé ou le propriétaire?`,
        not_officer_disclaimer:`Remarque: D’autres documents du signataire autorisé ou propriétaire sont requis. Notre équipe communiquera avec vous.`,
        yes:`Oui`,
        no:`Non`,
        stored_items_type:`Type d’articles entreposés`,
        approx_value:`Valeur approximative des articles entreposés`,
        commercial:`Commercial`,
        residential:`Résidentiel`,
        vehicle:`Véhicule`,
    },
}

export default additional_contacts