import ReactGA from 'react-ga';

export const event = ({category,action,label}) => {
    try{
        let payload = {category,action,label}
        // Send event to google analytics
        ReactGA.event(payload);

        if (process.env.DEBUG === "true") {
            console.log(`
                Analytics event:
            `,payload)
        }
    } catch (e){
        console.log(`Could not record analytics event: ${e}`)
    }
}