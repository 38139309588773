import React from 'react';
import localization from "~/src/static/localization/confirmYourIdentity/main"
import ShieldIcon from "../../static/images/icons/shield.svg";
import styled from "styled-components"
import SiteContext from "~/src/context/Site"

const Center = styled.div`
    text-align: center;
    
    p {
    width: 80%;
    margin: 0 auto;
    }
    
    img {
    margin-left: 20px;
    }
`

const Hero = () => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    return (
        <Center>
            <h1>
                {localization.title}
                <img src={ShieldIcon} alt="SVI Security"/>
            </h1>
            <p>{localization.subtitle}</p><p>{localization.content}</p>
        </Center>
    )
}

export default Hero