import regeneratorRuntime from "regenerator-runtime";
import Axios from "axios"
import {useState, useEffect, useContext} from "react";
import SiteContext from '../context/Site'
import alert from "../lib/alert";

/**
* @param {ParamDataTypeHere} available_units
* @return {number} number of days ahead to be made available to be booked by user
*/
const calculateRangeLength = (available_units) => {
    // if (available_units > 10) return 8*7 //Eight weeks
    // if (available_units > 5) return 6*7 //six week
    // return 1
    return 365
}

const isOpen = (date) => {
    const today = new Date();

    if (isSameDay(date, today) && !state.isTodayStillOpen) return false;

    //Check to see if day is within regular store hours
    const scheduledOpen = state.openOn[date.getDay()];
    if (!scheduledOpen) return false;

    //Check to see if day is explicitly closed
    if (state.closureDates) {
        const closedOnDay = state.closureDates.some(closedDay => isSameDay(closedDay, date))
        if (closedOnDay) return false
    }

    return true
};

function parseISOLocal(s) {
    var b = s.split(/\D/);
    return new Date(b[0], b[1] - 1, b[2]);
}

function isoToCanadianWeek(isoWeek) {
    let georgianWeek = [...isoWeek]; //[m,t,w,t,f,s,s] : iso week
    const sundayHours = isoWeek.pop();
    georgianWeek.unshift(sundayHours);
    return georgianWeek; //[s,m,t,w,t,f,s] : Canadian week
}

function isSameDay(date1, date2) {
    return date1.getDate() == date2.getDate() &&
        date1.getMonth() == date2.getMonth() &&
        date1.getFullYear() == date2.getFullYear();
}

export const useFacility = () => {
    const {facilityData} = useContext(SiteContext)

    const [state, setState] = useState({
        closureDates: [],
        openOn: new Array(7).fill(true),
        closingTodayAt: null,
        isTodayEverOpen: true,
        isTodayStillOpen: true,
    })

    const getClosureDates = async (facilityCode) => {
        try {
            if (state.closureDates.length === 0) {
                const url = `${process.env.DATAVAULT_NODEJS_API_URL}/facilities/${facilityCode}/closures`
                const headers = {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-api-key': `${process.env.DATAVAULT_NODEJS_API_KEY}`,
                  };
                let response = await Axios.get(url,{headers});
                const dateStrings = response.data.result;
                const dates = dateStrings.map(dateString => parseISOLocal(dateString))
                return dates
            }
        } catch (e) {
            console.warn('Error getting closure dates', e, JSON.stringify(e));
            return state.closureDates || [];
        }
    };

    // Converts 24 hour time string to am/pm string
    const militaryToMeridiemFormat = (military) => {
        const meridiem = new Date('1970-01-01T' + military + 'Z').toLocaleTimeString(
            {},
            {timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric'}
        );
        return meridiem
    }

    const isOpen = (date) => {
        const today = new Date();

        if (isSameDay(date, today) && !state.isTodayStillOpen) return false;

        //Check to see if day is within regular store hours
        const scheduledOpen = state.openOn[date.getDay()];
        if (!scheduledOpen) return false;

        //Check to see if day is explicitly closed
        if (state.closureDates) {
            const closedOnDay = state.closureDates.some(closedDay => isSameDay(closedDay, date))
            if (closedOnDay) return false
        }

        return true
    };

    const allowableDatesForReservation = (available_units) => {
        let numDaysAvailable = calculateRangeLength(available_units);

        const datesOffered = []
        let date = new Date()
        date.setHours(0,0,0,0)

        if(numDaysAvailable === 1 && isOpen(date) !== true) {
            numDaysAvailable++
        }

        let numDatesCounted = 0;
        do {
            // Get the next date that works
            const open = isOpen(date);
            if (open === true) {
                datesOffered.push(new Date(date));
                // numDatesCounted++;
            }
            numDatesCounted++;
            date.setDate(date.getDate() + 1)
        } while(numDatesCounted < numDaysAvailable)
        console.log('datesOffered', datesOffered);
        return datesOffered
    }

    const validate = (dateString, availableUnits = null, errorMessage = null) => {
        //https://stackoverflow.com/questions/5619202/converting-a-string-to-a-date-in-javascript
        var parts = dateString.split('-');
        var moveInDate = new Date(parts[0], parts[1] - 1, parts[2]);
        let canMoveInOnDay = true

        //Check to see if the move in date is within the dates offered
        if (availableUnits) {
            const dates = allowableDatesForReservation(availableUnits)
            const dateValid = dates.some(date => date.toDateString() == moveInDate.toDateString())
            if (!dateValid) canMoveInOnDay = false
        }

        //Check to see if the facility is open on this date
        if (!isOpen(moveInDate)) {
            canMoveInOnDay = false
        }

        if (!canMoveInOnDay && errorMessage) alert({
            title: errorMessage,
            type: "danger",
        })

        return canMoveInOnDay
    }

    useEffect(async () => {
        if (!facilityData) return
        const facilityHours = isoToCanadianWeek(Object.values(facilityData.hours.facility));
        const today = new Date()
        const todayHours = facilityHours[today.getDay()]
        const closingTodayAt = todayHours ? todayHours.split(" - ")[1] : '00:01' //'hh:mm'
        const closingHour = parseInt(closingTodayAt.split(":")[0]) // hh
        setState({
            openOn: facilityHours.map((day) => day ? true : false),
            closureDates: await getClosureDates(facilityData.id),
            closingTodayAt,
            isTodayEverOpen: !!facilityHours[today.getDay()],
            isTodayStillOpen: today.getHours() < (closingHour - 3),
        })
    }, [facilityData]);

    return {
        ...state, //closureDates, openOn, closingTodayAt, isTodayEverOpen, isTodayStillOpen
        closedForToday: !state.isTodayStillOpen && state.isTodayEverOpen,
        validate,
        isOpen,
        militaryToMeridiemFormat,
        allowableDatesForReservation,
    }
}
