import React from "react"
import styled from "styled-components"
import DocumentContext from '~/src/context/Document'
import SiteContext from '~/src/context/Site'
import localization from "~/src/static/localization/reservation/main"
import regeneratorRuntime from "regenerator-runtime";
import {useFacility} from "../../../../hooks"

const RedBox = styled.span`
  border: 1px solid red;
  padding: .5em;
`

export const InvalidDate = () => {
    const {language, facilityData} = React.useContext(SiteContext)
    const {contact_data, quote} = React.useContext(DocumentContext)
    const [displayMessage, setDisplayMessage] = React.useState(false)
    const [closingTime, setClosingTime] = React.useState("")
    const {validate, militaryToMeridiemFormat, closedForToday, closingTodayAt} = useFacility()
    localization.setLanguage(language)

    React.useEffect(async () => {
        if (!contact_data || !quote) return
        const openOnDay = validate(contact_data.client_information.move_in_date, quote.number_of_available_units)
        setDisplayMessage(!openOnDay)
    }, [contact_data, quote]);

    React.useEffect(() => {
        if (language === "english") setClosingTime(militaryToMeridiemFormat(closingTodayAt))
        else setClosingTime(closingTodayAt)
    }, [closingTodayAt, language])

    if (displayMessage) {
        const message = closedForToday ?
            localization.formatString(localization.client_information.information.already_closed_today, {
                    closing_time: closingTime,
                    facility_phone: facilityData ? facilityData.phone : "N/A",
                }
            )
            :
            localization.client_information.information.closed_on_date
        return <RedBox>{message}</RedBox>
    }

    return null
}
