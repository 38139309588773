import React from "react"
import localization from "~/src/static/localization/confirmation/move_in"
import styled from "styled-components"
import SiteContext from "~/src/context/Site"

const SuccessfulMessageStyle = styled.div`
    .description{
        margin-bottom: 15px;
    }
`;

const SuccessfulMessage = () => {
    const { language } = React.useContext(SiteContext)
    localization.setLanguage(language)
    let {messages} = localization.successful.details

    let paragraphs = messages.map((text,index)=>{
        return <p key={index}>{text}</p>
    })

    return (
        <SuccessfulMessageStyle>
            <div className="description">
                {paragraphs}
            </div>
        </SuccessfulMessageStyle>
    )
}

export default SuccessfulMessage