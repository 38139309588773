import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english: {
        storage_unit: `{0} Storage Unit`,
        protection_coverage: `Protection Plan `,
        rejected: {
            standard: {
                message: `You have chosen to opt out of the Protection Plan. For your protection and peace of mind, please note that your contents are required to be insured while in storage.`,
                prompt: `Select the reason for declining the Protection Plan coverage provided:`,
                reasons: [
                    `I will provide a copy of proof of insurance from my chosen provider.`,
                    // `I am storing a motor vehicle and will provide a copy of proof of insurance for the vehicle(s) listed on my Move-in documents from my chosen provider.`,
                    `I am storing strictly business and/or personal paper files and documents that hold no replacement value. No other items will be stored in my storage unit.`,
                ],
                other: `Other`,
            },
            vehicle: {
                message: `Stored vehicles do not qualify for the Protection Plan and thus are excluded within the Certificate of Protection.`,
                prompt: ``,
                reasons: [
                    `I am storing a motor vehicle and will provide a copy of proof of insurance for the vehicle listed on Schedule A - Parking from my chosen provider at time of check-in.`,
                ],
            },
        },

        accepted: {
            message: `For your immediate protection, we have provided you with free Protection Plan of {0} for the first 4 weeks.`,
        },
        line_items: {
            unit_price: {
                label: "Unit Rent",
                subtext: "(4 week cycle)",
            },
            unit_price_promotion: {
                label: "Promotion",
                subtext: "(4 weeks free)",
            },
            protection_first_month_discount: {
                label: "Promotion",
                subtext: "(4 weeks free)",
            },
            protection: {
                label: "Protection Coverage",
                subtext: "",
            },
            admin_fee: {
                label: "Admin Fee",
                subtext: "(one time)",
            },
            reservation_fee: {
                label: "Reservation Fee",
                subtext: "",
            },
            sub_total: {
                label: "Subtotal",
                subtext: "",
            },
            tax: {
                label: "Tax",
                subtext: "",
            },
            total: {
                label: "Cost to Move-in",
                subtext: "",
            },
        },
        discount: {
            description: {
                standard: "({num_weeks} weeks at {percentage}% off){asterisk}",
                free: "({num_weeks} weeks free){asterisk}"
            }
        }
    },
    french: {
        storage_unit: `Unité d’entreposage {0}`,
        protection_coverage: `Plan de protection`,
        rejected: {
            standard: {
                message: `Vous avez choisi de ne pas adhérer au plan de protection. Pour votre protection et votre tranquillité d’esprit, veuillez noter que vos biens doivent être assurés lorsqu’ils sont entreposés.`,
                prompt: `Sélectionnez la raison pour laquelle vous refusez la couverture du plan de protection fourni :`,
                reasons: [
                    `Je fournirai une copie de la preuve d’assurance du fournisseur de mon choix.`,
                    // `J’entrepose un véhicule motorisé et fournirai une copie de la preuve d’assurance du fournisseur de mon choix pour le(s) véhicule(s) figurant dans les documents d’emménagement.`,
                    `J’entrepose uniquement des dossiers et des documents commerciaux et/ou personnels papier qui ne possèdent aucune valeur de remplacement. Aucun autre bien ne sera entreposé dans mon unité d’entreposage.`,
                ],
                other: `Autre`,
            },
            vehicle: {
                message: `Les véhicules entreposés ne sont pas admissibles au plan de protection, et ils sont donc exclus du certificat de protection`,
                prompt: ``,
                reasons: [
                    `J’entrepose un véhicule motorisé et fournirai une copie de la preuve d’assurance du fournisseur de mon choix pour le(s) véhicule(s) figurant dans les documents d’emménagement.`,
                ],
            },
        },
        accepted: {
            message: `Pour votre protection immédiate, nous vous fournissons gratuitement un plan de protection de {0} pour les 4 premières semaines.`,
        },
        line_items: {
            unit_price: {
                label: "Location d’unité",
                subtext: "(cycle de 4 semaines)",
            },
            unit_price_promotion: {
                label: "Promotion",
                subtext: "(période de 4 semaines)",
            },
            protection_first_month_discount: {
                label: "Promotion",
                subtext: "(période de 4 semaines)",
            },
            protection: {
                label: "Couverture de protection",
                subtext: "",
            },
            admin_fee: {
                label: "Frais d’admin.",
                subtext: "(paiement unique)",
            },
            reservation_fee: {
                label: "Reservation Fee",
                subtext: "",
            },
            sub_total: {
                label: "Sous-total",
                subtext: "",
            },
            tax: {
                label: "Taxes",
                subtext: "",
            },
            total: {
                label: "Coût d’emménagement",
                subtext: "",
            },
        }, discount: {
            description: {
                standard: "({num_weeks} semaines à {percentage}% de rabais){asterisk}",
                free: "({num_weeks} semaines gratuites){asterisk}"
            }
        }

    }
});

export default LOCALIZATION