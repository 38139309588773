import React from "react";
import regeneratorRuntime from "regenerator-runtime";

import SiteContext from "~/src/context/Site"
import DocumentContext from "~/src/context/Document"
import Details from "./details/Main";
import HoursOfOperation from "~/src/components/common/assets/HoursOfOperation"
import FinePrint from "~/src/components/common/assets/FinePrint"
import MoveInMessage from "./moveInMessage/Main";
import {getPricing} from "~/src/REST"

const Confirmation = () => {
    const {facilityData,authToken} = React.useContext(SiteContext)
    const {setPricing} = React.useContext(DocumentContext)

    //Update pricing just to make sure this is the latest
    React.useEffect(async () => {
        if (authToken) {
            let pricing = await getPricing({authToken})
            setPricing(pricing)
        }
    },[authToken])

    return (
        <div className="container">
            <MoveInMessage/>
            <Details/>
            {facilityData && 
                    <HoursOfOperation
                        store={facilityData.hours.facility}
                        gate={facilityData.hours.unit}
                    />
                }
            <FinePrint />
        </div>
    )
}

export default Confirmation