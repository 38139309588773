import React from "react";
import styled from "styled-components";
import SiteContext from "~/src/context/Site"
import DocumentContext from "~/src/context/Document"
import localization from "~/src/static/localization/common/rental_details/line_items"

const PopupAcceptedStyle = styled.div`
    position: absolute;
    top: -30px;
    left: 50%;
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 1em;
    min-width: 120px;
    max-width: 100%;
    color: #666;
    font-size: 16px;
    border: solid 1px rgba(112,112,112,0.3);
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(S0,0,0,0.2);
    -webkit-box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
    @media (max-width: 768px) {
        display: block;
        position: relative;
        left: 0;
        top: 0;
        margin: 15px 0 25px 0;
    }

    :before {
        content: "";
        position: absolute;
        top: 50%;
        left: -23px;
        margin-top: -12px;
        border: 12px solid transparent;
        border-right: 12px solid #FFF;
        z-index: 2;
        @media (max-width: 768px) {
            top: -11px;
            left: 45px;
            border-bottom: 12px solid #FFF;
            border-right: 12px solid transparent;
        }
    }
    :after {
        content: "";
        position: absolute;
        top: 50%;
        left: -27px;
        margin-top: -13px;
        border: 13px solid transparent;
        border-right: 14px solid rgba(112,112,112,0.3);
        z-index: 1;
        @media (max-width: 768px) {
            top: -13px;
            left: 44px;
            border-bottom: 12px solid rgba(112,112,112,0.3);
            border-right: 12px solid transparent;
        }
    }
    p {
        margin: 0;
        padding: 0 0 1em;
    }
    p:last-of-type {
        padding: 0;
    }
    label {
        padding-top:0.5em;
    }
    input[type="radio"] {
        margin-right:10px;
    }
`;
const getInsuredValueByStoreTypeCost = (storeType) => {
    switch (storeType) {
        case "residential": return "5,000";
        case "": return "5,000";
        default: return "2,500"
    }
}

const PopUpAccepted = () => {
    const { language } = React.useContext(SiteContext)
    const { contact_data } = React.useContext(DocumentContext)
    let {stored_items_type} = contact_data.additional_information
    localization.setLanguage(language)

    const insured_value = getInsuredValueByStoreTypeCost(stored_items_type)
    return (
        <PopupAcceptedStyle>
            <p>{localization.formatString(localization.accepted.message, `$${insured_value}`)}</p>
        </PopupAcceptedStyle>
    )
}

export default PopUpAccepted
