import React from "react";
import StandardLineItem from "./StandardLineItem";
import ProtectionCoverage from "./ProtectionCoverage/Main";
import DiscountLineItem from "./DiscountLineItem";
import ProtectionDiscountLineItem from "./ProtectionDiscountLineItem";

const selectComponent = (data, index, display_protection = true, display_popup = false, onChangeAcknowledgement, show_asterisk) => {
    const {type,amount,applied} = data
    const style = display_popup?{gridTemplateColumns:'repeat(4,1fr)'}:""

    switch (type) {
        case "protection":
            if (display_protection) return <ProtectionCoverage key={index}
                                                                display_popup={display_popup}
                                                                amount={amount}
                                                                style={style}
                                                                onChangeAcknowledgement={onChangeAcknowledgement} />
            else return

        case "protection_first_month_discount":
            if (display_protection) return <ProtectionDiscountLineItem  key={index}
                                                                        type={type}
                                                                        amount={amount}
                                                                        style={style}
                                                                        applied={applied}/>
            else return

        case "unit_price_promotion":
        case "discount":
            return <DiscountLineItem    key={index}
                                        type={"unit_price_promotion"}
                                        amount={amount}
                                        style={style}
                                        show_asterisk= {show_asterisk}/>

        default:
            return <StandardLineItem    key={index}
                                        type={type}
                                        amount={amount}
                                        applied={applied} style={style}/>
    }
}

export default selectComponent
