import regeneratorRuntime from "regenerator-runtime";
import Axios from "axios";

require('dotenv').config();

if (!process.env.DATAVAULT_NODEJS_API_URL) throw "Environment Variable 'DATAVAULT_NODEJS_API_URL' does not exist or is undefined"

/**
* Retrieves Contract Details
* @param {String} reference_id - the reference code of the contract
* @return {Object} The completed form from reservations component
*/
export const getFacilityData = async (facility_id) => {
    var url = `${process.env.DATAVAULT_NODEJS_API_URL}/facilities/${facility_id}`
    const headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'x-api-key': `${process.env.DATAVAULT_NODEJS_API_KEY}`,
    };
    let response = await Axios.get(url, {headers})
    if(response.data.isSuccess === true) {
        const facilityData = response.data.result
        const faciilty = {
            "name": facilityData.title,
            "brand": facilityData.full_brand,
            "phone": facilityData.phone,
            "id": facilityData.facility_id,
            "google_place_id": facilityData.google_place_id,
            "image_url": facilityData.original_image,
            "address": {
                "city": facilityData.address_details.city,
                "postal_code": facilityData.address_details.postal_code,
                "province": facilityData.address_details.province,
                "street_1": facilityData.address_details.street_1,
                "street_2": facilityData.address_details.street_2,
            },
            "hours": {
                "unit": {
                    "monday": facilityData.open_hours.Monday === "Closed" ? null : facilityData.open_hours.Monday,
                    "tuesday": facilityData.open_hours.Tuesday === "Closed" ? null : facilityData.open_hours.Tuesday,
                    "wednesday": facilityData.open_hours.Wednesday === "Closed" ? null : facilityData.open_hours.Wednesday,
                    "thursday": facilityData.open_hours.Thursday === "Closed" ? null : facilityData.open_hours.Thursday,
                    "friday": facilityData.open_hours.Friday === "Closed" ? null : facilityData.open_hours.Friday,
                    "saturday": facilityData.open_hours.Saturday === "Closed" ? null : facilityData.open_hours.Saturday,
                    "sunday": facilityData.open_hours.Sunday === "Closed" ? null : facilityData.open_hours.Sunday,
                },
                "facility": {
                    "monday": facilityData.store_hours.Monday === "Closed" ? null : facilityData.store_hours.Monday,
                    "tuesday": facilityData.store_hours.Tuesday === "Closed" ? null : facilityData.store_hours.Tuesday,
                    "wednesday": facilityData.store_hours.Wednesday === "Closed" ? null : facilityData.store_hours.Wednesday,
                    "thursday": facilityData.store_hours.Thursday === "Closed" ? null : facilityData.store_hours.Thursday,
                    "friday": facilityData.store_hours.Friday  === "Closed" ? null : facilityData.store_hours.Friday,
                    "saturday": facilityData.store_hours.Saturday === "Closed" ? null : facilityData.store_hours.Saturday,
                    "sunday": facilityData.store_hours.Sunday === "Closed" ? null : facilityData.store_hours.Sunday,
                }
            },
            "amenities": []
        }
        
        return faciilty
    }
    console.log(JSON.stringify(response))
    return {}
}