
import styled from "styled-components";

const Indicator = styled.div`
    display:flex;
    justify-content:space-between;
    margin: -15px 0 -20px 0;
`;

export default Indicator
