import React from "react"
import { useHistory } from "react-router";
import {analytics} from "~/src/lib/analytics"
import {RentOnlineStyle} from "./styles"
import NextButton from "../../common/NextButton";
import localization from "~/src/static/localization/landing/rent_online"
import SiteContext from "~/src/context/Site"

const RentOnline = () => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    const history = useHistory();
    const displayForm = () => {
        history.push("/reservation")

        analytics.event({
            category: "Confirm Client Information",
            action: "Click",
            label: "Next",
        })
    }

    return(
        <RentOnlineStyle>
            <h2>{localization.header}</h2>
            <p>{localization.body}</p>
            <NextButton bgcolor="#04BE45" onClick={displayForm}>{localization.button}</NextButton>
        </RentOnlineStyle>
    )
}

export default RentOnline