import brands from "~/src/static/header/brands";

const getBrandInfo = (brand_id) => {
    for (let brand of brands){
        if (brand.id == brand_id) return brand
    }

    //brand id unrecongized, return our default
    return brands[0]
}

export default getBrandInfo