import React from "react"
import DocumentContext from "~/src/context/Document"

const Reason = ({text, label="", onChangeAcknowledgement}) => {
    const {pricing,setPricing} = React.useContext(DocumentContext)
    let {protection_plan} = pricing
    let {exemption} = protection_plan

    const handleReasonChange = (new_reason) => {
        let new_pricing = {...pricing}
        new_pricing.protection_plan.exemption.chosen_reason = new_reason
        setPricing(new_pricing)
        onChangeAcknowledgement()
    };

    if (text!="Other") {
        let selected = (text == exemption.chosen_reason)
        return (
            <label>
                <input type="radio" checked={selected} onChange={() => handleReasonChange(text)}/>
                {text}
            </label>
        )
    }
    else {
        let selected = !exemption.available_reasons.includes(exemption.chosen_reason)
        return (
            <label>
                <input type="radio" checked={selected} onChange={() => handleReasonChange("")}/>{label}
                <input  onChange={({target:{value}})=>handleReasonChange(value)}
                        name="other"
                        type="text"
                        value={selected? exemption.chosen_reason:""}/>
            </label>
        )
    }
}

export default Reason