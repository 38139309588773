import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        reserve: {
            header:"Thank you for your reservation, {name}!",
            info:`  We are holding a {unit_size} for you at our {facility} store.
                    In a few minutes you will receive a call from a friendly Storage Advisor to review the details of your reservation and provide an overview of the move-in process.`,
            sub_text_1: "storage unit",
            call_back_prompt:"",
        },
        call_me_back: {
            header:"Thank you for your storage inquiry, {name}!",
            info:`  We are holding a {unit_size} for you at our {facility} store. Within the next few minutes, you will receive a call from a friendly Storage Advisor
                    to review the details of your inquiry and provide an overview of the move-in process.`,
            sub_text_1: "storage unit",
            call_back_prompt:"Please note that you will receive an inbound call from {phone_number}",
        },
    },
    french: {
        reserve: {
            header:"Merci de votre réservation, {name}!",
            info:`  Nous conservons une unité d’entreposage de {unit_size} pour vous à la succursale {facility}.
                    Dans quelques minutes, vous recevrez un appel d'un sympathique conseiller en entreposage qui passera en revue les détails de votre réservation et vous donnera un aperçu du processus d'emménagement.`,
            sub_text_1: "",
            call_back_prompt:"",
        },
        call_me_back: {
            header:"Merci de votre demande d’entreposage {name}!",
            info:`  Nous conservons une unité d’entreposage de {unit_size} pi pour vous à la succursale {facility}.
                    Dans les prochaines minutes, vous recevrez un appel d'un sympathique conseiller en entreposage qui passera en revue les détails de votre demande et vous donnera un aperçu du processus d'emménagement.`,
            sub_text_1: "",
            call_back_prompt:"Veuillez noter que vous recevrez un appel entrant provenant du numéro {phone_number}",
        },
    }
});

export default LOCALIZATION