import React from "react"
import styled from "styled-components"
import Store from "~/src/components/common/Store"
import Image from "~/src/components/common/assets/Image"
import User from "~/src/components/common/User"
import LineItems from "~/src/components/reservation/paymentInformation/LineItems/Main"
import localization from "~/src/static/localization/confirmation/move_in"
import SiteContext from "~/src/context/Site"
import DocumentContext from "~/src/context/Document"
import SuccessfulMessage from "./SuccessfulMessage";
import { Fragment } from "react"

const DetailsStyle50 = styled.div`
    font-family:'Hind', 'Arial', sans-serif;
    display:grid;
    grid-auto-columns:50%;
    h3 {   
        font-size:1.8rem;
        margin-top:20px;
        margin-bottom:5px;
        font-weight:bold;
        grid-area: headline;
    }
    .detail-list{
        grid-area: list;
    }
    .image {
        grid-area: image;
    }
    p {
        margin-bottom:5px;
        &:last-child {
            margin-bottom:0;
        }
    }
    grid-template:
    'headline headline'
    'list image';
    grid-gap:1em;
    color:#666;
    @media (max-width: 768px) {
        display: block;
    }
`;

const Details = () => {
    const {pricing} = React.useContext(DocumentContext)
    const {language,isMoveInSuccessful,facilityData} = React.useContext(SiteContext)
    const [displayProtection,setDisplayProtection] = React.useState(true)
    localization.setLanguage(language)
    let labels = localization.details
    let {header:header_message} = isMoveInSuccessful? localization.successful.details : localization.unsuccessful.details
    React.useEffect(()=>{
        if (pricing && pricing.protection_plan) setDisplayProtection(pricing.protection_plan.applied)
    },[pricing])

    return (
        <Fragment>
            <DetailsStyle50>
                <h3>{header_message}</h3>
            </DetailsStyle50>
            {isMoveInSuccessful && <SuccessfulMessage/>}
            <DetailsStyle50>
                <div className="detail-list">
                    <User localization={labels} completed={true} />
                    <Store/>
                    <LineItems display_protection={displayProtection} display_popup={false} show_asterisk={true}/>
                </div>
                <div className="image">
                    {facilityData &&
                        <Image img_url={facilityData.image_url} />}
                </div>
            </DetailsStyle50>
        </Fragment>
    )
}

export default Details