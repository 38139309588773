import React from "react"
import localization from "~/src/static/localization/landing/introduction"

const getPhoneNumber = (brand) => {
    switch (brand){
        case "as": return "1-888-383-7721";
        case "dp": return "1-888-816-7072";
        case "sf": return "1-888-997-5328";
        case "se": return "1-888-853-9064";
        default: return "1-888-853-9064";
    }
};

export const getCallBackPrompt = (quote,language) => {
    if (quote.quote_type === "reserve") return null;
    localization.setLanguage(language);
    const message = localization.formatString(localization.call_me_back.call_back_prompt,{
        phone_number: getPhoneNumber(quote.brand),
    });
    return <p className="font-weight-bold">{message}</p>
}