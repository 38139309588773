import Contract from "./Contract";
import React from "react";
import styled from "styled-components";

const ContractTextStyle = styled.div`
    overflow-y: scroll;
    scroll-behavior: smooth;
    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:vertical {
        width: 11px;
    }
    
    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .5);
    }
    
    /* Add shadows to indicate scrolling - https://lea.verou.me/2012/04/background-attachment-local/ */
    background:
        /* Shadow covers */
            linear-gradient(white 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
        
        /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;

    height: calc(100vh - 430px);
    @media (max-width: 575px) {
        height: calc(100vh - 456px);
    }
`;

const ContractText = ({index, language, activeKey, scrollable, contract_type}) => {

    return (
        <ContractTextStyle ref={scrollable}>
            {activeKey == index &&
            <Contract index={index} language={language} contract_type={contract_type}/>
            }
        </ContractTextStyle>
    )
}

export default ContractText;