import React from "react";
import styled from "styled-components";
import localization from "~/src/static/localization/misc"
import SiteContext from "~/src/context/Site"

const FinePrintSection = styled.section`
    border-top:1px solid #666;
    padding:15px 0;
    margin-top:30px;
    color:#666;
`;

const FinePrint = () => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    return (
        <FinePrintSection>{localization.fine_print}</FinePrintSection>
    );
    
}

export default FinePrint