import React from 'react';
import localization from "~/src/static/localization/confirmYourIdentity/main"
import SiteContext from "~/src/context/Site"
import PopOutStyle from "./PopOutStyle"
import Popup from "./Popup";
import styled from "styled-components"

const Button = styled.button`
    border: 3px solid #04BE45;
    border-radius: 10px;
    background-color: white;
    padding: 5px 40px;
    color: #04BE45;
    font-weight: bold;
`

const Desktop = ({sessionUrl}) => {
    const {language} = React.useContext(SiteContext)
    const [showPopup,setShowPopup] = React.useState(false)
    localization.setLanguage(language)
    const labels = localization.option_two

    return (
        <PopOutStyle>
            <h2>{labels.header}</h2>
            <p>{labels.desc_one}</p>
            <p>{labels.desc_two}</p>
            <Button type="button" className="btn btn-lg btn-success" onClick={() => setShowPopup(true)}>
                {labels.start}
            </Button>
            {sessionUrl && <Popup sessionUrl={sessionUrl} show={showPopup} setShow={setShowPopup}/>}
        </PopOutStyle>
    )
}

export default Desktop