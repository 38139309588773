const getBreadCrumbs = ({clientX,clientY,pageX,pageY,screenX,screenY,timeStamp,view}) => (
    {
        clientX,clientY,pageX,pageY,screenX,screenY,
        timeStamp_unix: +new Date(),
        timeStamp_string: new Date(),
        appVersion:view.navigator.appVersion

    }
)

export default getBreadCrumbs
