import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        standard_text: "I, {first_name} {last_name}, agree to the terms and conditions of the {contract_name}.",
    },
    french: {
        standard_text: "Je, {first_name} {last_name}, accepte les conditions de {contract_name}. ",
    }
});

export default LOCALIZATION