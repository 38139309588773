import ReactGA from 'react-ga';
import {event} from "./event";

const getGoogleAnalyticsCode = (short_name) => {
    switch (short_name) {
        case "access": return "UA-5924598-1";        // Access Storage
        case "depotium": return "UA-73616746-1";      // Depotium Mini-Entrepôt
        case "sentinel": return "UA-380113-18";       // Sentinel Storage
        case "sfyl": return "UA-1422532-1";          // Storage for Your Life
        default: return null;
    }
}

export const initialize = (quoteBrand) => {
    try{
        // Initialize Google Analytics
        ReactGA.initialize(getGoogleAnalyticsCode(quoteBrand.short_name));

        event({
            category: "Homepage",
            action: "Visit",
            label: "On-Load"
        })

        if (process.env.DEBUG === "true") {
            console.log(`
                Analytics intialization:
                    ${quoteBrand.short_name}
            `)
        }
    } catch (e){
        console.log(`Could not intialize analytics: ${e}`)
    }
}