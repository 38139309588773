import regeneratorRuntime from "regenerator-runtime";
import {buildConfig} from "./lib"
import Axios from "axios"
if (!process.env.ERO_BACKEND_URL) throw "Environment Variable 'ERO_BACKEND_URL' does not exist or is undefined"

/** 
* Retrieves Pricing details from the backend server
* @param {String} token - Valid JWT token that lies in context
* @param {{}} changes - Objects that we want to change
* @return {{}} Pricing details
*/
export const putPricingProtectionPlan = async (authToken,protection_plan) => {
    let config = buildConfig(authToken);
    let url = `${process.env.ERO_BACKEND_URL}/pricing/protection_plan`;
    let response = await Axios.put(url,protection_plan,config);
    return response.data;
}