import React from "react";
import styled from "styled-components";

const Green = "#04BE45"
const SliderStyle = styled.div` 
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-left:5px;

  input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E2002D;
    -webkit-transition: .1s;
    transition: .1s;
    border-radius: 20px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .1s;
    transition: .1s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: ${Green};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${Green};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }  
`;
const Slider = ({onClick,applied,disabled}) => {
    const toggleCheck = () => {
        if(!disabled) onClick(!applied);
    }

    return (
        <SliderStyle onClick={toggleCheck}>
            <input type="checkbox" onChange={toggleCheck} checked={applied}/>
            <span className="slider"></span>
        </SliderStyle>
    )
}

export default Slider