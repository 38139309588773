import styled from "styled-components";
import React from "react";

const Arrow = styled.div`
    
    div {
        position: absolute;
    right: 6px;
    top: ${props => {
    switch (props.direction) {
        case 'up':
            return "0"
        case "down":
            return props.height + 20 + 'px'
        default:
            return "auto"
    }
    }};
    z-index: 5;
    height: 20px;
    width: 20px;
    background-color: transparent;
    
    ::after {
    position: absolute;
    left: 3px;
    top: ${props => props.direction === "down" ? "0" : "10px"};
    transform: ${props => props.direction === "down" ? "rotate(180deg)" : "rotate(0)"};
    content: '';
    z-index: 6;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid gray;
    }
}
`

//scrollable prop is a React ref in the parent
const ScrollArrow = ({direction, scrollable, height}) => {
    let scrollInterval;

    const handleMouseDown = (props) => {
        if (props === "up") {
            scrollable.current.scroll(0, scrollable.current.scrollTop - 100)
            scrollInterval = setInterval(() => scrollable.current.scroll(0, scrollable.current.scrollTop - 100), [100])
        }
        if (props === "down") {
            scrollable.current.scroll(0, scrollable.current.scrollTop + 100)
            scrollInterval = setInterval(() => scrollable.current.scroll(0, scrollable.current.scrollTop + 100), [100])
        }
    }

    window.addEventListener("mouseup", () => {
        clearInterval(scrollInterval)
    })

    return (
        <Arrow direction={direction} height={height}>
            <div onMouseDown={() => handleMouseDown(direction)}
            ></div>
        </Arrow>
    )
}

export default ScrollArrow;