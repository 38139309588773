import regeneratorRuntime from "regenerator-runtime";
import React from "react"
import styled from "styled-components"
import DocumentContext from '~/src/context/Document'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFacility } from "../../../../hooks"
const CalenderIcon = require('~/src/static/images/icons/icon-calender.png');

const CalendarButton = styled.button`
    width: 34px;
    height: 28px;
    background: url(${CalenderIcon}) no-repeat;
    border: 0;
`

const Calendar = ({onChange}) => {
    const { quote } = React.useContext(DocumentContext);
    const { allowableDatesForReservation, closureDates } = useFacility();
    const [offeredDates, setOfferedDates] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    React.useEffect(async ()=>{
        if (quote) {
            const dates = allowableDatesForReservation(quote.number_of_available_units);
            setOfferedDates(dates);
        }
    },[quote,closureDates]);

    const handleChange = (date) => {
        setSelectedDate(date);
        onChange(date);
    }

    const ButtonInput = React.forwardRef(({ onClick }, ref) => (
        <CalendarButton className="datepicker-input" onClick={onClick} ref={ref}/>
    ));

    return (
        <DatePicker selected={selectedDate}
                    onChange={handleChange}
                    includeDates={offeredDates}
                    customInput={<ButtonInput/>}/>
    );
}

export default Calendar
