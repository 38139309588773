import regeneratorRuntime from "regenerator-runtime";
import {buildConfig} from "./lib"
import Axios from "axios";
if (!process.env.ERO_BACKEND_URL) throw "Environment Variable 'ERO_BACKEND_URL' does not exist or is undefined"

/** 
* Retrieves Pricing details from the backend server
* @param {String} token - Valid JWT token that lies in context
* @return {{}} Pricing details
*/
export const getPricing = async ({authToken,estimate=false}) => {
    const config = buildConfig(authToken);
    let estimateString = estimate? "/estimate" : "";
    var url = `${process.env.ERO_BACKEND_URL}/pricing` + estimateString;
    let response = await Axios.get(url,config);
    return response.data;
}