import React from "react";
import styled from "styled-components";
import FormField from "~/src/components/common/FormField"
import ExpiryField from "~/src/components/common/ExpiryField"
import SiteContext from "~/src/context/Site"
import localization from "~/src/static/localization/reservation/main"
import InputMask from "react-input-mask";

const defaultGrey = '#666';
const defaultFont = "'Hind', 'Arial', sans-serif";
const CreditIcon = require('~/src/static/images/icons/icon-creditcard.png');
const CardInformationStyle = styled.div`
    color:${defaultGrey};
    font-family:${defaultFont};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    h4 {
        font-weight:bold;
        margin:2rem 0 1rem 0;
    }
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

`;
const HalfAreaForm = styled.div`
    width:calc(50% - 0.5em);
    @media (max-width: 768px) {
        width: 100%;
    }
`;
const CreditCardStyle = styled.div`
    input[name=number]{
        background: url(${CreditIcon}) no-repeat calc(100% - 5px);
        background-size:80px;
    }
`;
const DoubleField = styled.div`
    display: grid;
    gap: 0.5em;
    grid-template-columns: auto auto;
    align-items: center;
    color:#666;
`;



const CardInformation = ({onChange, data}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    const labels = localization.payment_information

    const handleChange = event => {
        const {value, name} = event.target;
        // WEB-875: Temporary remove hyphen from credit - card information
        if (name == 'number') onChange(name, window.btoa(value.replace(/-/g, "").replace(/_/g, "")))
        else onChange(name,window.btoa(value))
    };

    const handleExpiryChange = (value) => {
        let payload = {target:{name:"expiry",value}}
        handleChange(payload)
    }

    return (
        <CardInformationStyle>
            <h4>{labels.header}</h4>
            <p className="font-weight-bold">{labels.subheader}</p>
            <p>{labels.legal_authorization_text}</p>
            <HalfAreaForm>
                <FormField required={true} requiresDecode={true} label={labels.card.holder_name} onChange={handleChange}
                           type="text" formData={data} name="name" custom={{pattern: "[-,a-zA-Z \'\.]*"}}/>
                <CreditCardStyle>
                    <FormField required={true} requiresDecode={true} label={labels.card.number} onChange={handleChange}
                               type="credit_card" formData={data} name="number" />
                </CreditCardStyle>
                <DoubleField>
                    <ExpiryField formData={data} onChange={handleExpiryChange} label={labels.card.expiry} required={true}/>
                    <FormField
                        custom={{
                            pattern: "[0-9]{3,4}",
                            placeholder: "000"}}
                               required={true} requiresDecode={true} label={labels.card.security_code}
                               onChange={handleChange} type="text" formData={data} name="security_code"/>
                </DoubleField>
            </HalfAreaForm>
        </CardInformationStyle>
    )
}

export default CardInformation