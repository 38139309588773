import React from "react"
import styled from "styled-components"
import Store from "~/src/components/common/Store"
import Image from "~/src/components/common/assets/Image"
import User from "~/src/components/common/User"
import LineItems from "../reservation/paymentInformation/LineItems/Main"
import localization from "~/src/static/localization/landing/reservation_details"
import SiteContext from "~/src/context/Site"

const RentalDetailsStyle = styled.div`
    display:grid;
    grid-auto-columns:50%;
    h3 {
        font-size:1.8rem;
        margin-top:20px;
        margin-bottom:15px;
        font-weight:bold;
        grid-area:headline;
    }
    .detail-list{
        grid-area: list;
    }
    .image {
        grid-area: image;
    }
    p {
        margin-bottom:5px;
        &:last-child {
            margin-bottom:0;
        }
    }
    grid-template:
    'headline headline'
    'list image';
    grid-gap:1em;
    margin:50px 0;
    @media (max-width: 768px) {
        display: block;
    }
`;

const RentalDetails = ({pricing,quote}) => {
    const {language,facilityData} = React.useContext(SiteContext)
    localization.setLanguage(language)

    return (
        <RentalDetailsStyle>
            <h3>{localization.header}</h3>
            <div className="detail-list">
                {quote && <User localization={localization} quote={quote}/>}
                <Store />
                {pricing && quote &&
                    <LineItems display_protection={false} display_popup={false} unit_size={`${quote.unit_width}'x${quote.unit_length}'`} show_asterisk={true} />
                }
            </div>
            <div className="image">
                {facilityData && 
                <Image img_url={facilityData.image_url}/>}
            </div>
        </RentalDetailsStyle>
    )
}

export default RentalDetails