import React from "react"
import Modal from 'react-bootstrap/Modal'
import SiteContext from '~/src/context/Site';
import {useHistory} from 'react-router-dom';

const Popup = ({sessionUrl,setShow, show}) => {
    const {setIdentityVerificationCompleted,setIsMoveInSuccessful} = React.useContext(SiteContext)
    let history = useHistory();
    const handleClose = () => {
        setIdentityVerificationCompleted(true)
        setIsMoveInSuccessful(true) //Anyone that has gotten to the popup must have had a succesful move in
        setShow(false);
        history.push('/reservation/confirmation')
    }
    const [height,setHeight] = React.useState()

    React.useEffect(()=>{
        let newHeight = window.innerHeight*.8 > 550? window.innerHeight*.8 : 550;
        setHeight(newHeight)
    },[window.innerHeight])

    return (
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton/>
            <Modal.Body>
                <iframe src={sessionUrl}
                        width="100%"
                        height={height}
                        allow="camera"
                        allowFullScreen={true}/>
            </Modal.Body>
        </Modal>
    );
}

export default Popup