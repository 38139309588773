import React, {Fragment, useEffect} from 'react';
import localization from "~/src/static/localization/confirmYourIdentity/main"
import styled from "styled-components"
import SiteContext from "~/src/context/Site"
import {postIdentityVerification} from "../../REST";
import regeneratorRuntime from "regenerator-runtime";
import Partner from './Partner';
import Hero from "./Hero"
import Mobile from "./Mobile"
import Desktop from "./Desktop"
import {Flex, FlexItem} from "./Flex"

const ConfirmYourIdentityField = styled.div`

    color:#666;
    font-family:'Hind', 'Arial', sans-serif;

    h1,
    h2 {
        margin-bottom:25px;
        font-weight:bold;
    }
    
    h1 {
        font-size:2rem;
        text-align:center;
    }
    
    h2 {
        font-size: 1.5rem;
    }
`

const BoxShadowLine = styled.div`
    width: 100vw;
    height: 3px;
    margin-bottom: 50px;
    border-bottom: 1px solid #b7b7b7;
    box-shadow: 0 3px 3px #d7d7d7;
`

const IdentityVerification = () => {
    const {authToken,language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    const [sessionUrl, setSessionUrl] = React.useState("")

    const grabSessionUrl = async () => {
        let {url} = await postIdentityVerification({authToken,language});
        setSessionUrl(url)
    }

    useEffect( () => {
        if (authToken) grabSessionUrl();
    }, [authToken])

    return (
        <Fragment>
            <BoxShadowLine/>
            <ConfirmYourIdentityField className="container">
                <Hero/>
                <Flex>
                    <FlexItem width={"48%"}>
                        <Mobile sessionUrl={sessionUrl}/>
                    </FlexItem>
                    <FlexItem width={"48%"}>
                        <Desktop sessionUrl={sessionUrl}/>
                    </FlexItem>
                </Flex>
                <Partner/>
            </ConfirmYourIdentityField>
        </Fragment>
    )
}

export default IdentityVerification;