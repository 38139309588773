import React from "react"
import SiteContext from "~/src/context/Site";
import localization from "~/src/static/localization/authentication"

const Disabled = () => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    return (
        <React.Fragment>
            <h1>{localization.title}</h1>
            <p>{localization.back_to_email_cta}</p>
        </React.Fragment>
    )
}

export default Disabled