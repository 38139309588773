import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        header: `Reservation Details`,
        confirmation_number: `Your Confirmation Number:`,
        move_in_date: `Approximate Date Needed:`,
    },
    french: {
        header: `Détails de la réservation`,
        confirmation_id: ``,
        move_in_date:`Date d’emménagement souhaitée :`,
    }
});

export default LOCALIZATION