import React from "react";
import styled from "styled-components";

const LightGrayBorder = "#AFAFAF"
const Green = "#04BE45"
const StepDiv = styled.div`
    width:100%;
    font-size:0.85rem;
    padding:10px 10px 15px 10px;
    background-color:#F4F4F4;
    @media (max-width: 768px) {
        padding: 10px 2px 10px 2px;
    }

    span {
        visibility:hidden;
        font-size:1.5em;
        font-weight:900;
    }
    span.completed {
        color:${Green};
        visibility: visible;
    }
    &.current-step {
        background-color:#fff;
        border-top: 1px solid ${LightGrayBorder};
        border-right: 1px solid ${LightGrayBorder};
        border-left: 1px solid ${LightGrayBorder};
        border-bottom:1px solid #fff;
        margin-bottom:-2px;
    }
    @media (max-width: 992px) {
        border: none !important;
        background: none !important;
        margin: 0 !important;
    }
`;

const HideInMobile = styled.div`
    @media (max-width: 992px) {
        display: none;
    }
`;

const Bar = styled.div`
    margin:5px 0;
    height:10px;
    width:auto;
    &.default {
        background-color:${LightGrayBorder}; 
    }
    &.current {
        background-color:${Green};
    }
    &.completed {
        background-color:${LightGrayBorder};  
    }
`;

const getStatus = (currentStep,myStep) => {
    if (currentStep > myStep) {return "completed"} else
    if (currentStep == myStep){return "current"} else
    if (currentStep < myStep) {return "default"}
}

const Step = ({myStep,currentStep,content}) => {
    const status = getStatus(currentStep,myStep)
    return ( 
        <StepDiv className={`${status}-step`}>
            <HideInMobile>{content} <span className={status}>&#10003;</span></HideInMobile>
            <Bar className={status}></Bar>
        </StepDiv>
    )
}

export default Step
