import React from "react"
import localization from "~/src/static/localization/landing/introduction"

export const getInfoString = (local,quote,facility) => {
    const unitSize = quote?`${quote.unit_width}' x ${quote.unit_length}'`: TempData.unit_size;
    const unitSizeText = <span className="font-weight-bold">{unitSize} {local.sub_text_1}</span>
    const facilityText = <span className="font-weight-bold">{facility.brand} - {facility.name}</span>
    const phoneNumber = <span>{facility.phone}</span>
    return localization.formatString(local.info,{
        unit_size: unitSizeText,
        facility: facilityText,
        phone_number: phoneNumber,
    })
}