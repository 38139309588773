import styled from "styled-components"

export const RentOnlineStyle = styled.div`
    font-family:'Hind', 'Arial', sans-serif;
    margin:20px 0;
    padding:30px 100px 15px 100px;
    border:1px solid #008AE0;
    border-radius:5px;
    text-align:center;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    @media (max-width: 768px) {
        padding:30px 50px 15px 50px;
    }
    h2 {
        font-family:'Hind', 'Arial', sans-serif;
        font-size:1.8rem;
        margin-bottom:15px;
        font-weight:bold;
        text-align:center;
    }
    p {
      margin-bottom:0;
    }
`;