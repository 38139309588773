import regeneratorRuntime from "regenerator-runtime";
import {buildConfig} from "./lib"
import Axios from "axios";
if (!process.env.ERO_BACKEND_URL) throw "Environment Variable 'ERO_BACKEND_URL' does not exist or is undefined"

/** 
* Sends contract details to the backend server
* @param {{contractDetails,token}} - The completed form from reservations component, and jwt token that lies in context
* @return {String} Reference code
*/
export const getQuote = async (authToken) => {
    const config = buildConfig(authToken);
    let url = `${process.env.ERO_BACKEND_URL}/quote`;
    let response = await Axios.get(url,config);
    return response.data;
}