import React from "react"

const DocumentContext = React.createContext({
    contact_data: {},
    setContact_data: () => {},
    payment_data: {},
    setPayment_data: () => {},
    terms_and_conditions: {},
    setTerms_and_conditions: () => {},
    quote_id: undefined,
    setQuote_id: () => {},
    pricing: undefined,
    setPricing: () => {},
    quote: {},
    setQuote: () => {},
    setContractTerms: () => {},
    contactBreadCrumbs: {},
    setContactBreadCrumbs: () => {},
});

export default DocumentContext