const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '').trim();

    // "(980) 914-2333"
    if (cleaned.length === 10) {
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) return [match[1], match[2], match[3]].join("-");
    }
    // "+1 (980) 154-2335"
    else if (cleaned.length === 11 && cleaned.startsWith(1)) {
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) return [match[2], match[3], match[4]].join("-");
    }
    return str;
};

export default formatPhoneNumber