import React from "react";
import Disabled from "./Disabled";
import Enabled from "./Enabled";
import localization from "~/src/static/localization/authentication"
import SiteContext from "~/src/context/Site";
import Modal from "react-bootstrap/Modal";

const ModalContent = () => {
    if (process.env.DISABLE_ON_SITE_LOGIN !== "true") return (<Enabled/>)
    else return <Disabled/>
}

const Authentication = () => {
    const {authToken,language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    if (authToken == ""){
        return (
            <Modal show={true}>
                <Modal.Body>
                    <ModalContent/>
                </Modal.Body>
            </Modal>
        );
    }else{
        return null
    }
}

export default Authentication