import styled from "styled-components"

export const IntroductionStyle = styled.div`
    padding: 2rem 0;
    margin-bottom:3rem;
    background-color:#F4F4F4;
    h1 {
        font-family:'Hind', 'Arial', sans-serif;
        font-size:2rem;
        margin-bottom:20px;
        font-weight:bold;
        text-align:center;
    }
    p {
        text-align:center;
    }
`;