import React from "react"
import styled from "styled-components"
import SiteContext from "~/src/context/Site"
import DocumentContext from "~/src/context/Document"
import localization from "~/src/static/localization/common/rental_details/line_items"
import Currency from "./Currency"

const DiscountLineItemStyle = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1em;
    @media (max-width: 768px) {
        grid-template-columns: 3fr 1fr !important;
    }
    label {
        margin:0;
    }
    div:last-child{
        text-align:right;
    }
`

const ProtectionDiscountLineItem = ({amount, style, always_show = false}) => {
    const {pricing} = React.useContext(DocumentContext)
    const {protection_plan} = pricing?pricing:{}
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    const {label,subtext} = localization.line_items["protection_first_month_discount"]
    if (protection_plan) return (
        <DiscountLineItemStyle style={{...style}}>
            <div>
                <label>{label}</label> {subtext}
            </div>
            <div>
                <Currency amount={protection_plan.applied || always_show ?
                    amount
                    :
                    undefined
                }/>
            </div>
        </DiscountLineItemStyle>
    )
    else return null
}

export default ProtectionDiscountLineItem