import React from "react"
import styled from "styled-components"
import localization from "~/src/static/localization/landing/steps"
import SiteContext from "~/src/context/Site"

const StorageStepStyle = styled.div`
    h3 {
        font-size:1.8rem;
        margin-top:20px;
        margin-bottom:15px;
    }
    margin:50px 0;
`;
const ListStyle = styled.ul `

    counter-reset: li;
    list-style-type: none;
    
    li {
        position: relative;
        padding:1.5rem 1rem 1.5rem 7rem;
        border-bottom:3px solid #E3E3E3;
        font-size:1.2rem;
        vertical-align:middle;
        min-height:7rem;
        &:last-child {
            border-bottom: none;
        }
    }
    li::before {
        content: counter(li);
        counter-increment: li;
        color: #ffffff;
        font-size: 2.2rem;
        font-weight: bold;
        position: absolute;
        --size: 64px;
        left: 15px;
        top: 24px;
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        
        background:#707070;
        border-radius: 50%;
        text-align: center;
    }
`;

const StorageSteps = () => {
    const {brandInfo:{shop_url}, language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    const step_3 = localization.formatString(
        localization.step_3,
        <a href={shop_url} target="_blank" rel="noopener noreferrer">{localization.step_3_hyperlink_text}</a>
    )
    const steps = [
        localization.step_1,
        localization.step_2,
        step_3
    ]

    return (
        <StorageStepStyle>
            <h3>{localization.header}</h3>
            <ListStyle>
                {steps.map((text,i) => (
                    <li key={i}>{text}</li>
                ))}
            </ListStyle>
        </StorageStepStyle>
    )
}

export default StorageSteps