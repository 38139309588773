import regeneratorRuntime from "regenerator-runtime";
import Axios from "axios"
if (!process.env.ERO_BACKEND_URL) throw "Environment Variable 'ERO_BACKEND_URL' does not exist or is undefined"

/** 
* Posts for a JWT token
* @param {{id:string,email:string}} Credentials
* @return {String} JWT token
*/
export const postToken = async ({id,email}) => {
    let url = `${process.env.ERO_BACKEND_URL}/token/${id}`;
    let response = await Axios.post(url,{email});
    return response.data;
}