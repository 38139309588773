import React from "react";
import styled from "styled-components";
import regeneratorRuntime from "regenerator-runtime";
import Button from "react-bootstrap/Button"

import SiteContext from "~/src/context/Site"
import DocumentContext from '~/src/context/Document'
import localization from "~/src/static/localization/common/acknowledgements"
import getBreadCrumbs from "~/src/lib/getBreadCrumbs"

const Green = "#04BE45"
const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 30px auto;
    margin: 1em 0;
    button {
        padding: 0px 1px 1px 0px;
        font-size:16px;
        line-height: 1;
        color:#fff;
        vertical-align: middle;
        font-weight:900;
        width: 20px;
        height: 20px;
        margin-top: 3px;
    }
    .btn-primary,.btn-primary:hover,.btn-primary:focus,.btn-primary:not(:disabled):not(.disabled):active {
        background-color: ${Green};
        border: 2px solid ${Green};
    }
    button.btn-box {
        background-color:#fff;
        border: 2px solid #707070;
    }
    span {
        color:#666;
        font-size: 1em;
        font-weight: 700;
    }
`;

const Acknowledgement = ({
        text=null,
        onClick,
        signed=null,
        setSigned=null,
        contract_name=null
    }) => {
    const {language} = React.useContext(SiteContext)
    const {contact_data} = React.useContext(DocumentContext)
    const {first_name,last_name} = contact_data.client_information

    if (signed == null || setSigned == null){
        [signed,setSigned] = React.useState(false)
    }
    
    localization.setLanguage(language)
    if (text == null || text == ""){
        text = localization.formatString(
            localization.standard_text,
            {first_name,last_name,contract_name}
        )
    }

    const recordClick = async (event) => {
        let breadCrumbs = getBreadCrumbs(event)
        setSigned(true)

        // If onClick is not null, that means the parent component wants to handle the logic instead, do not post to backend just yet
        onClick(breadCrumbs)
    }

    return (
        <React.Fragment>
            <ButtonContainer>
                <Button type="button" onClick={recordClick} className={signed ? "":"btn-box"} disabled={signed ? true:false}>
                &#10003;
                </Button>
                <span onClick={recordClick} >{text}</span>
            </ButtonContainer>
        </React.Fragment>
    )
}

export default Acknowledgement