import styled from "styled-components"
import React from "react"

export const PopOutStyle = styled.div`
margin-top: 50px;
padding: 2rem;
box-shadow: 3px 6px 15px rgba(0,0,0,.15);
border-radius: 5px;
border: 1px solid gray;
min-height: 300px;
`

export const RecommendedPopOutStyle = styled(PopOutStyle)`
    border: 1px solid #04BE45;
    position: relative;
    
    &::after {
      content: 'RECOMMENDED';
      font-weight: bold;
      color: white;
      background-color: #04BE45;
      padding: 0 10px;
      position: absolute;
      left: -1px;
      top: -24px;
      border-radius: 5px 5px 0 0;
    }
`

export default PopOutStyle