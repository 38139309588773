let today = new Date
const contact = {
    client_information: {
        first_name: "",
        last_name: "",
        company_name: "",
        street_address: "",
        city: "",
        province: "",
        postal_code: "",
        phone_number: "",
        alt_phone_number: "",
        email: "",
        move_in_date: today.toISOString().split("T")[0],
        note: "",
    },
    additional_information: {
        stored_items_type: "residential",
        approximate_value: "",
        include: false,
        is_signing_officer: true,
        name: ""
    },
    acknowledgement: "",
}

export default contact