import React from "react"
import { useLocation } from "react-router-dom";
import regeneratorRuntime from "regenerator-runtime";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken"

import DocumentContext from "~/src/context/Document"
import {getFacilityData,getPricing,getQuote} from "~/src/REST"
import useQuery from "~/src/lib/useQuery"
import tokenValid from "~/src/lib/tokenValid"
import getBrandInfo from "~/src/lib/getBrandInfo"

import SiteContext from "~/src/context/Site"
import {analytics} from "~/src/lib/analytics"


//Not a react display component, but something added to abstract intialization logic of the webpage
const Intialization = () => {
    const {authToken,brandInfo,language,setBrandInfo,setAuthToken,setLanguage,setFacilityData} = React.useContext(SiteContext)
    const {setQuote_id,setPricing,setQuote,contact_data,setContact_data} = React.useContext(DocumentContext)
    const [analyticsIntialized,setAnalyticsIntialized] = React.useState(false)
    const query = useQuery()
    const history = useHistory()

    React.useEffect(()=>{
        // Add prefix title
        //TODO: Move this into localizations
        document.title = brandInfo.name + " - StorageVault Documents";
    },[language,brandInfo])

    React.useEffect(async () => {
        const token = query.get("token")
        if (tokenValid(token)) setAuthToken(token)

        const brand = query.get("brand")
        if (brand != undefined) {
          let brandInfo = getBrandInfo(brand)
          setBrandInfo(brandInfo)
        }

        const language = query.get("language")
        if (language != undefined) {
          setLanguage(language)
        }

        //Erase query parameters if any exists
        if (token || brand || language) history.push({search:""})
    },[query])

    React.useEffect(async () => {
        if (authToken) {
            try{
                var {quote_id} = jwt.decode(authToken)
                if (quote_id) setQuote_id(quote_id)
            }catch (e){
                console.log("No valid JWT")
            }

            var [pricing,quote] = await Promise.all([
                getPricing({authToken,estimate:true}),
                getQuote(authToken)
            ])

            setPricing(pricing)
            setQuote(quote)

            if (quote) {
                const quoteBrand = getBrandInfo(quote.brand);
                setLanguage(quote.language)
                setBrandInfo(quoteBrand)
                const quote_phone_number = (quote.phone_number && (quote.phone_number.startsWith("+1"))) ?quote.phone_number.slice(2) :"";
                let {client_information} = contact_data
                let newContact_data = {
                    ...contact_data,
                    client_information: {
                        ...client_information,
                        first_name: quote.first_name,
                        last_name: quote.last_name,
                        email: quote.email,
                        phone_number: quote_phone_number,
                        move_in_date: quote.start_date,
                    }
                }
                setContact_data(newContact_data)

                // Add meta tag to head
                analytics.initialize(quoteBrand)
                setAnalyticsIntialized(true)
            }

            if (quote && quote.facility_id) {
                let data = await getFacilityData(quote.facility_id)
                setFacilityData(data)
            }
        } else {
            // TODO: Speed up debugging process as per Amir
            if (process.env.NODE_ENV === 'development') {
                let default_quote_id, default_quote_email = process.env
                if (default_quote_id && default_quote_email) setAuthToken(await postToken({ id: default_quote_id, email: default_quote_email}))
            }
        }
    },[authToken])

    const { pathname } = useLocation();
    React.useEffect(() => {
        // Auto scrollTo up in each page loading
        window.scrollTo(0, 0);

        if(analyticsIntialized) analytics.pageview(pathname)
    }, [pathname,analyticsIntialized]);
    
    return null
}

export default Intialization