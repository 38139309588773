import React from "react";
import styled from "styled-components";
import {useRouteMatch} from "react-router-dom";
import Step from "./Step";
import localization from '../../../static/localization/reservation/main';
import SiteContext from '~/src/context/Site';

const StepsBarWrapper = styled.div`
    padding: 0;
    background-color: #F4F4F4;
    margin-bottom:2rem;
    border-bottom: 1px solid #AFAFAF;
    box-shadow: 0px 3px 4px -4px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 3px 4px -4px rgba(0,0,0,0.3);
`;

const StepsBarStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 0px;
`;

const MobileHeader = styled.div`
    text-align: center;
    padding-top: 15px;
    display: none;
    @media (max-width: 992px) {
        display: block;
    }
`;

const getCurrentStep = () => {
    let current
    try{
        current = useRouteMatch("/reservation/:slug").params.slug
    }catch (e){
        current = ""
    }
    switch(current) {
        case "":                    return 1
        case "contact_information": return 1
        case "payment_information": return 2
        case "sign_contracts":      return 3
        case "confirmation":        return 4
        default:                return 0
    }
}

const StepsBar = () => {
    let current = getCurrentStep()

    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    const labels = localization.title

    const steps = [
        {stepNo: '1', content: labels.section_1.tab},
        {stepNo: '2', content: labels.section_2.tab},
        {stepNo: '3', content: labels.section_3.tab},
        {stepNo: '4', content: labels.section_4.tab},
    ];

    const stepItems = steps.map(({stepNo,content})=>
    (
        <Step key={stepNo} myStep={stepNo} currentStep={current} content={content}/>
    ));

    return (
        <StepsBarWrapper>
            <MobileHeader>
                {steps[current-1].content}
            </MobileHeader>
            <StepsBarStyle className="container">
                {stepItems}
            </StepsBarStyle>
        </StepsBarWrapper>
    )
}

export default StepsBar
