import React from "react";
import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import { useHistory } from "react-router";
import ContactInformation from "./contactInformation/Main"
import SignContract from "./signContract/Main"
import PaymentInformation from "./paymentInformation/Main"
import StepsBar from "./StepsBar/Main";
import Confirmation from "./confirmation/Main";

const ReservationForm = () => {
    let { path, url } = useRouteMatch();
    const history = useHistory();

    return (
        <React.Fragment>
            <StepsBar/>
            <Switch>
                <Route path={`${url}/contact_information`} component={ContactInformation}/>
                <Route path={`${url}/payment_information`} component={PaymentInformation}/>
                <Route path={`${url}/sign_contracts`} component={SignContract}/>
                <Route path={`${url}/confirmation`} component={Confirmation}/>
                <Route path="/" component={ContactInformation}/>
            </Switch>
        </React.Fragment>
    )
}

export default ReservationForm