import styled from "styled-components";
import React, {useRef} from "react";
import SiteContext from "~/src/context/Site";
import localization from "~/src/static/localization/reservation/main";
import {browserErrorMessages} from "~/src/static/localization/validation";

const ExpiryFieldStyle = styled.div`
    width: 100%;
    background-color: white;
    margin-bottom: 0.5rem;
        
    select {
        appearance: none;
        display:block;
        font-size:1em;
        width:100%;
        font-weight: normal;
        border-radius: 4px;
        padding: 0.3em;
        margin-top: 0.25em;
        border:1px solid rgba(102,102,102,0.7);
        color:#666;
    }
    
    > div {
        display: flex;
        align-items: center;
        
        div {
            position: relative;
            flex-grow: 1;
            
            &::after {
                content: '';
                position: absolute;
                height: 0;
                width: 0;
                border-top: 8px solid gray;
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                right: 10px;
                top: 18px;
                pointer-events: none;
            }
        }
    }
        
    span {
        margin: 0 5px;
    }
    
    label {
        margin-bottom: 0;
    }
`

const ExpiryField = ({formData, onChange, label, required}) => {

    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    browserErrorMessages.setLanguage(language)
    let labels = localization.payment_information.card

    const selectRef = useRef();

    const populateNumberSelect = (min, max, type) => {
        let optionsArray = [];
        for (let i = min; i <= max; i++) {
            let val = ('0' + i).slice(-2)
            optionsArray.push(<option value={val} key={`${type}-${val}`}>{val}</option>)
        }

        const getInitialField = () => {
            switch (type) {
                case 'month': return <option disabled value="" key={type}>{labels.month_default}</option>
                case 'year': return <option disabled value="" key={type}>{labels.year_default}</option>
                default: return null
            }
        }

        optionsArray.unshift(getInitialField()) //Inserts new element at the start of an array
        return optionsArray;
    }

    const consolidateChange = (event) => {
        let {value,name} = event.target;
        let expiry = window.atob(formData["expiry"])

        if (expiry.length === 0) {
            expiry = "YYYY-MM"
        }

        if (name === "month") {
            let newExpiry = expiry.slice(0, -2) + value
            onChange(newExpiry)
        }

        if (name === "year") {
            let newExpiry = "20" + value + expiry.slice(4);
            onChange(newExpiry)
        }
    }

    //show custom error on form submit
    const customValidity = (e) => {
        e.target.setCustomValidity(browserErrorMessages.default)
    }

    //update to relevant error as input as user enters data, or else remove error
    const checkValidity = (e) => {
        if (e.target.validity.patternMismatch) {
            e.target.setCustomValidity(browserErrorMessages.wrong_format)
        } else e.target.setCustomValidity('');
    }

    return <ExpiryFieldStyle>
        <label onClick={() => selectRef.current.focus()}>{`${label}${required ? "*" : ""}`}</label>
        <div>
            <div>
                <select onInvalid={(e) => customValidity(e)}
                                 onBlur={(e) => checkValidity(e)}  ref={selectRef} onChange={consolidateChange} required name={"month"}
                        defaultValue={""}>
                    {populateNumberSelect(1, 12, 'month')}
                </select>
            </div>
            <span>/</span>
            <div>
                <select onInvalid={(e) => customValidity(e)}
                        onBlur={(e) => checkValidity(e)} onChange={consolidateChange} required name={"year"} defaultValue={""}>
                    {populateNumberSelect(21, 32, 'year')}
                </select>
            </div>
        </div>
    </ExpiryFieldStyle>
}

export default ExpiryField;