import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        title_store: "Store Hours",
        title_facility: "Facility Access Hours",
        monday: `Monday`,
        tuesday: `Tuesday`,
        wednesday: `Wednesday`,
        thursday: `Thursday`,
        friday: `Friday`,
        saturday: `Saturday`,
        sunday: `Sunday`,
        open_24_hours: `24 Hours`,
        closed: `Closed`,
    },
    french: {
        title_store: "Heures d’ouverture de la succursale",
        title_facility: "Heures d’accès à l’installation",
        monday: `Lundi`,
        tuesday: `Mardi`,
        wednesday: `Mercredi`,
        thursday: `Jeudi`,
        friday: `Vendredi`,
        saturday: `Samedi`,
        sunday: `Dimanche`,
        open_24_hours: "24 h/24",
        closed: `Fermé`,
    }
});

export default LOCALIZATION