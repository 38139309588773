import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        currency: "${amount}",
    },
    french: {
        currency: "{amount}$",
    }
});

export default LOCALIZATION