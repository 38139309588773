import regeneratorRuntime from "regenerator-runtime";
import {buildConfig} from "./lib"
import Axios from "axios"
if (!process.env.ERO_HTML_PDF_URL) throw "Environment Variable 'ERO_HTML_PDF_URL' does not exist or is undefined"

/**
* Retrieves Contract HTML
* @param {String} reference_id - the reference code of the contract
* @return {Object} The completed form from reservations component
*/
export const postContractHTML = async ({contract_type,language,authToken,exclude_html=false}) => {
    let config = buildConfig(authToken);
    let url = `${process.env.ERO_HTML_PDF_URL}/HTML`;
    let payload = {contract_type,language,exclude_html};
    let response = await Axios.post(url,payload,config);
    return response.data;
}