import React from "react"
import ScrollArrow from "./ScrollArrow";

const ScrollArrows = ({scrollableHeight,scrollable}) => {
    if (scrollableHeight) return (
        <React.Fragment>
            <ScrollArrow direction={"up"} scrollable={scrollable} height={scrollableHeight}/>
            <ScrollArrow direction={"down"} scrollable={scrollable} height={scrollableHeight}/>
        </React.Fragment>
    )
    else return null
}

export default ScrollArrows