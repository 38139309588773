import React from "react"
import styled from "styled-components"
import DocumentContext from "~/src/context/Document"
import formatPhoneNumber from '../../lib/formatPhoneNumber';

const UserStyle = styled.div`
    border-top:3px solid #E3E3E3;
    margin:0;
    padding:1rem 0;
`;

const User = ({localization,completed=false}) => {
        const {contact_data,quote} = React.useContext(DocumentContext)
        const [personal_info,setPersonal_info] = React.useState({})

        //See if we can use the data within contact_data first, if they dont exist, use the data from qutoe
        React.useEffect(() => {
            let {first_name,last_name,phone_number,email} = contact_data.client_information
            let info = {first_name,last_name,phone_number,email}
            for (let [key,value] of Object.entries(info)) if(!value || value=="") info = {...info,[key]:quote[key]}
            setPersonal_info(info)
        }, [contact_data])

        const {move_in_date, street_address, city, province, postal_code} = contact_data.client_information

        let address
        if (street_address && city &&  province && postal_code) address = `${street_address}, ${city}, ${province}, ${postal_code}`

    return (
        <UserStyle>
            <p><span className="font-weight-bold">{localization.move_in_date}</span> {move_in_date}</p>
            <div className="font-weight-bold" style={{"marginTop":"0.5rem"}}>{personal_info.first_name} {personal_info.last_name}</div>
            {completed && <div>{address}</div>}
            <div>{personal_info.email}</div>
            <div>{formatPhoneNumber(personal_info.phone_number)}</div>
        </UserStyle>
    )
}

export default User