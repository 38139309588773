const brands = [
  {
    id: 'ac',
    name: 'Access Storage',
    short_name: 'access',
    url: 'https://www.accessstorage.ca',
    shop_url: 'https://shop.accessstorage.ca/',
    logo: require ('~/src/static/images/logo-access.png'),
    logoFooter: require ('~/src/static/images/logo-access.png'),
    social: [
      {
        name: 'facebook',
        link: 'http://www.facebook.com/AccessStorageCA',
        icon: require ('~/src/static/images/social/facebook-circle.png'),
      },
      {
        name: 'twitter',
        link: 'https://www.twitter.com/AccessStorageCA',
        icon: require ('~/src/static/images/social/twitter-circle.png'),
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/accessstorageca',
        icon: require ('~/src/static/images/social/instagram-circle.png'),
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/656556',
        icon: require ('~/src/static/images/social/linkedin-circle.png'),
      },
      {
        name: 'youtube',
        link: 'https://www.youtube.com/channel/UC7Xa2eFm9gsOljI_VMWpWwQ',
        icon: require ('~/src/static/images/social/youtube-circle.png'),
      },
    ],
  },
  {
    id: 'dp',
    name: 'Depotium',
    short_name: 'depotium',
    url: 'https://www.depotium.com',
    shop_url: 'https://shop.depotium.com/',
    logo: require ('~/src/static/images/logo-depotium.png'),
    logoFooter: require ('~/src/static/images/logo-depotium-light.png'),

    social: [
      {
        name: 'facebook',
        link: 'http://www.facebook.com/Depotium-Mini-Entrep%C3%B4t-122034967822950',
        icon: require ('~/src/static/images/social/facebook-circle.png'),
      },
      {
        name: 'twitter',
        link: 'https://www.twitter.com/depotium',
        icon: require ('~/src/static/images/social/twitter-circle.png'),
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/depotiumminientrepot/?hl=en',
        icon: require ('~/src/static/images/social/instagram-circle.png'),
      },
    ],
  },
  {
    id: 'sf',
    name: 'Storage For Your Life',
    short_name: 'sfyl',
    url: 'https://www.storageforyourlife.com',
    logo: require ('~/src/static/images/logo-sfyl.png'),
    logoFooter: require ('~/src/static/images/logo-sfyl-light.png'),
    social: [
      {
        name: 'facebook',
        link: 'http://www.facebook.com/storageforyourlife',
        icon: require ('~/src/static/images/social/facebook-circle.png'),
      },
      {
        name: 'twitter',
        link: 'https://www.twitter.com/s4ylife',
        icon: require ('~/src/static/images/social/twitter-circle.png'),
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/storageforyourlife/?hl=en',
        icon: require ('~/src/static/images/social/instagram-circle.png'),
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/1036560',
        icon: require ('~/src/static/images/social/linkedin-circle.png'),
      },
    ],
  },
  {
    id: 'se',
    name: 'Sentinel',
    short_name: 'sentinel',
    url: 'https://www.sentinel.ca',
    shop_url: 'https://shop.sentinel.ca/',
    logo: require ('~/src/static/images/logo-sentinel.png'),
    logoFooter: require ('~/src/static/images/logo-sentinel-light.png'),
    social: [
      {
        name: 'facebook',
        link: 'http://www.facebook.com/sentinelstoragecanada',
        icon: require ('~/src/static/images/social/facebook-circle.png'),
      },
      {
        name: 'twitter',
        link: 'https://www.twitter.com/sentinelstorage',
        icon: require ('~/src/static/images/social/twitter-circle.png'),
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/sentinelstorageca',
        icon: require ('~/src/static/images/social/instagram-circle.png'),
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/sentinel-storage',
        icon: require ('~/src/static/images/social/linkedin-circle.png'),
      },
    ],
  },
  {
    id: '5',
    name: 'Cubeit',
    short_name: 'cubeit',
    url: 'https://www.cubeit.ca',
    logo: require ('~/src/static/images/logo-cubeit.png'),
  },
  {
    id: '6',
    name: 'PUPS',
    short_name: 'pups',
    url: 'https://www.potablecontainer.ca',
    logo: require ('~/src/static/images/logo-pups.png'),
  },
  {
    id: '7',
    name: 'StorageVault Canada',
    short_name: 'svi',
    url: 'https://www.storagevaultcanada.com',
    logo: require ('~/src/static/images/logo-storagevault.png'),
  },
];

export default brands;
