import React from 'react';
import localization from "~/src/static/localization/confirmYourIdentity/main"
import styled from "styled-components"
import Image from "../common/assets/Image";
import SiteContext from "~/src/context/Site"

const PartnerStyle = styled.div`
  width: 200px;
  margin-top: 50px;

  div {
    width: 50px;
  }
  
  h3 {
      margin-top: 10px;
      font-weight: bold;
      font-size: 0.8rem;
  }
  
  p {
      font-size: 0.7rem;
  }
  
  a {
      font-weight: bold;
      appearance: none;
  }
`

const Partner = () => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    return (
        <PartnerStyle>
            <div>
                <Image isLocal img_url={require("~/src/static/images/logo-yoti.png")} altText={"Yoti logo"}/>
            </div>
            <h3>{localization.yoti_info.title}</h3>
            <p>
                {localization.yoti_info.learn_more}
                <a href={"https://www.yoti.com/"}>Yoti.com</a>
            </p>
        </PartnerStyle>
    )
}

export default Partner