import React from 'react';
import styled from "styled-components";
import {postContractHTML} from "~/src/REST";
import SiteContext from '~/src/context/Site';
import regeneratorRuntime from "regenerator-runtime";

const IframeStyle = styled.iframe`
    zoom: 2;
    transform: scale(2);
    transform-origin: 0 0;
    -moz-transform: scale(2);
    -moz-transform-origin: 0 0;
    -o-transform: scale(2);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(2);
    -webkit-transform-origin: 0 0;
`;

const adjustFrameSize = (frame) => {
    frame.style.width = '50%';
    if (navigator.userAgent.indexOf("Firefox") !== -1){
        frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
    } else {
        frame.style.height = `${frame.contentWindow.document.body.scrollHeight/2}px`;
    }
}

const writeDocument = ({contentDocument:doc},content) => {
    doc.open();
    doc.write(content);
    doc.close();
}

const Contract = ({index,contract_type}) => {
    const {authToken, language} = React.useContext(SiteContext)

    React.useEffect(async ()=>{
        let {__html} = await postContractHTML({contract_type, language, authToken})
        var iframe = document.getElementById(`${index}_${contract_type}`);
        writeDocument(iframe,__html)
        adjustFrameSize(iframe)
    },[authToken,language])

    return (
        <IframeStyle id={`${index}_${contract_type}`} scrolling="no" frameBorder="0"/>
    );
};

export default Contract;