import React from "react"

const SiteContext = React.createContext({
    authToken: undefined,
    setAuthToken: () => {},
    brandInfo: {},
    setBrandInfo: () => {},
    facilityData: {},
    setFacilityData: () => {},
    language: "english",
    setLanguage: () => {},
    redirectedToConfirmation: null,
    setRedirectedToConfirmation: () => {},
    identityVerificationCompleted: false,
    setIdentityVerificationCompleted: () => {},
});

export default SiteContext