const client_information = {
    english: {
        title: "Client Information Form",
        information: {
            move_in_date: `Move-in Date:`,
            closed_on_date: `Please choose an alternate move-in date, the store office is closed on this date.`,
            already_closed_today: `Our store is open until {closing_time} today. Please call us at {facility_phone} or select a different move-in date.`,
            first_name: `Legal First Name`,
            last_name: `Legal Last Name`,
            company_name: `Company Name`,
            street_address: `Street Address`,
            city: `City`,
            province: `Province`,
            postal_code: `Postal Code`,
            phone: {
                primary: `Primary Phone`,
                alternate: `Mobile/Alternate Phone`,
            },
            email: `Email`,
            info: {
                first_name: `The spelling of your name must match your government issued ID`,
                last_name: `The spelling of your name must match your government issued ID`,
            },
        },
        acknowledgement: `I, {first_name} {last_name}, confirm to {store_name},
                         that the information given in the Client Information Form is true,
                         complete and accurate.`,
        error_message: {
            acknowledgement_missing: {
                title: "Acknowledgement Not Signed",
                message: "Acknowledgement not accepted.  In order to proceed, please check the box to accept the terms and conditions.",
            }
        },
        success_message:{
            contract_signed: {
                title: "Success!",
                message: `You have successfully verified your contact information.`,
                type: "success", 
            },
        },
    },
    french: {
        title: "Formulaire de renseignements sur le client",
        information: {
            move_in_date: `Date d’emménagement :`,
            closed_on_date: `Veuillez choisir une autre date d'emménagement, car la succursale est fermée à cette date.`,
            first_name: `Prénom`,
            last_name: `Nom`,
            company_name: `Nom de l’entreprise`,
            street_address: `Adresse`,
            city: `Ville`,
            province: `Province`,
            postal_code: `Code postal`,
            phone: {
                primary: `Téléphone principal`,
                alternate: `Cellulaire ou autre`,
                work: `No de téléphone au travail`,
            },
            email: `Courriel`,
            info: {
                first_name: `L’orthographe de votre nom doit correspondre à celle qui figure sur votre pièce d'identité délivrée par le gouvernement.`,
                last_name: `L’orthographe de votre nom doit correspondre à celle qui figure sur votre pièce d'identité délivrée par le gouvernement.`,
            }
        },
        acknowledgement: "Je, {first_name} {last_name}, confirme à {store_name} que les renseignements contenus dans le formulaire de renseignements sur le client sont vrais, complets et corrects.",
        error_message: {
            acknowledgement_missing: {
                title: "Confirmation non signée",
                message: "La confirmation n’est pas acceptée. Pour continuer, veuillez cocher la case pour accepter les conditions.",
            }
        },
        success_message:{
            contract_signed: {
                title: "Succès!",
                message: `Vos coordonnées ont été vérifiées avec succès.`,
                type: "success",
            },
        },
    }
}

export default client_information
