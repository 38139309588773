import React from "react"
import { ErrorMessage } from "./ErrorMessage";

/*
React is migrating over to Functional components over class component.
Currently support for error boundaries only exists for class components.
https://reactjs.org/docs/error-boundaries.html

I expect there to be React support for functional error boundaries in the future.
There is third party support currently for a functional error boundary but implementing would confuse future devs.
https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c

TODO: Convert this component to functional once React supports it.
*/
export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    //TODO: Add some sort of error logging here
    // componentDidCatch(error, errorInfo) {
    //   //Whatever we want to do
    // }

    render() {
      if (this.state.hasError) return <ErrorMessage/>;
      else return this.props.children; 
    }
}