import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import alert from '~/src/lib/alert'

import regeneratorRuntime from "regenerator-runtime";
import {postContractHTML} from "~/src/REST";
import Acknowledgement from "~/src/components/common/Acknowledgement"
import NextButton from '~/src/components/common/NextButton'
import Indicator from '~/src/components/common/assets/Indicator'
import DocumentContext from '~/src/context/Document'
import SiteContext from '~/src/context/Site';
import localization from "~/src/static/localization/contracts"
import ContractText from "./ContractText";
import recordContract from "~/src/lib/recordContract"
import Banner from "./Banner"
import ScrollArrows from "./ScrollArrows"

const CardStyle = styled(Card)`
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const AccordionSection = ({activeKey, index, contract_type, switchKey, submit}) => {
    const {authToken, language} = React.useContext(SiteContext)
    const {terms_and_conditions, setTerms_and_conditions} = React.useContext(DocumentContext)
    const [cannotProceed,setCannotProceed] = React.useState(true)
    const [breadcrumbs,setBreadCrumbs] = React.useState({})
    const [contractRecorded,setContractRecorded] = React.useState(false)
    const [title,setTitle] = React.useState("")
    const [contractVersion,setContractVersion] = React.useState("")
    localization.setLanguage(language)

    const fetchHtml = async () => {
        let {contract_version,recommended_contract_title} = await postContractHTML({contract_type, language, authToken, exclude_html: true})
        setTitle(recommended_contract_title)
        setContractVersion(contract_version)
    }

    React.useEffect(() => {
        if (authToken) fetchHtml();
    }, [authToken,language,contract_type])

    //create ref for grabbing height of contract that needs to be scrollable
    let componentRef = useRef();

    //store ref values in state to allow for rerendering
    const [componentHeight, setComponentHeight] = React.useState(componentRef.current && componentRef.current.clientHeight)

    //listen for window resizing and change distance between arrows accordingly
    window.addEventListener('resize', () => {
        setComponentHeight(componentRef.current && componentRef.current.clientHeight);
    })

    //grab scrollable height again if new accordion section opens
    useEffect(() => {

        //use counter to clear interval after a few fires
        let counter = 0;

        //use interval to ensure new contract has loaded before grabbing height
        const watchContract = setInterval(() => {
            setComponentHeight(componentRef.current && componentRef.current.clientHeight);
            counter++;

            //clear interval after a few tries
            if (counter > 3) clearInterval(watchContract);
        }, 250);
    }, [activeKey])

    const onAcknowledgementClick = (breadcrumb) => {
        setBreadCrumbs([{...breadcrumb,contract_type}])
        setCannotProceed(false)
    }

    const nextStep = async () => {
        if (cannotProceed) {
            let {acknowledgement_missing} = localization.message
            alert({
                title:acknowledgement_missing.title,
                message:acknowledgement_missing.message
            })
        } else {
            if (!contractRecorded){
                let id = await recordContract({
                    breadcrumbs,
                    contract_type,
                    contract_name: title,
                    contract_version: contractVersion,
                    language,
                    authToken,
                })
                setTerms_and_conditions([...terms_and_conditions,id])
                setContractRecorded(true)
            }
            switchKey(index, true)
        }
    }

    return (
        <CardStyle>
            <Banner completed={contractRecorded} title={title}/>
            <Accordion.Collapse eventKey={`${index}`}>
                <Card.Body style={{position: "relative"}}>
                    <ScrollArrows scrollable={componentRef} scrollableHeight={componentHeight}/>
                    <ContractText scrollable={componentRef} index={index} language={language} activeKey={activeKey} contract_type={contract_type}/>
                    <Acknowledgement contract_name={title} contract_type={contract_type} onClick={onAcknowledgementClick}/>
                    <Indicator>
                        <NextButton bgcolor="#AFAFAF" direction={false} onClick={() => switchKey(index, false)}/>
                        <NextButton submit={submit} onClick={nextStep}/>
                    </Indicator>
                </Card.Body>
            </Accordion.Collapse>
        </CardStyle>
    )
}

export default AccordionSection;
