import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english:{
        header: "Express Rent Now",
        body: ` Quick, convenient, and 100% contact-free! Simply complete the forms, e-sign the agreement and a Storage Advisor will reach out to confirm your rental so that you can skip the front desk and 
            move-in at your convenience.`,
        button: `Rent Now >>`,
    },
    french: {
        header: "Effectuez une location rapide dès maintenant",
        body: ` Une solution rapide, pratique et 100 % sans contact! Vous n’avez qu’à remplir les formulaires, à signer électroniquement l’entente et un conseiller en entreposage vous contactera pour confirmer votre location afin que vous puissiez éviter la réception et emménager à votre convenance.`,
        button: `Louer maintenant >>`,
    }
});

export default LOCALIZATION