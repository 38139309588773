import React from "react"
import styled from "styled-components"
import SiteContext from '~/src/context/Site'
import localization from "~/src/static/localization/reservation/main"
import Calendar from "./Calendar"
import { InvalidDate } from "./InvalidDate"

const ComponentStyle = styled.span`
    display:grid;
    grid-template-columns: auto auto;
    align-items: center;
    width: fit-content;
    margin-bottom:1.5em;
    column-gap: 1em;
`;

const MoveInDateStyle = styled.span`
    display:grid;
    gap:0.5em;
    grid-template-columns:auto auto auto;
    align-items: center;
    width: fit-content;
    color:#666;
`;

const MoveInDate = ({onChange,data}) => {
    const { language } = React.useContext(SiteContext)

    localization.setLanguage(language)
    const formData = data? data["client_information"]:{};

    const dateChange = (date) => {
        onChange("client_information",{...formData,move_in_date:date.toISOString().split("T")[0]})
    }

    return (
        <ComponentStyle>
            <MoveInDateStyle>
                <div><b>{localization.client_information.information.move_in_date}</b></div>
                <span>{formData.move_in_date}</span>
                <Calendar onChange={dateChange}/>
            </MoveInDateStyle>
            <InvalidDate/>
        </ComponentStyle>
    )
}

export default MoveInDate