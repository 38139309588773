import React from "react";
import Slider from "./Slider";
import styled from "styled-components";
import SiteContext from "~/src/context/Site";
import localization from "~/src/static/localization/common/rental_details/line_items";
import Currency from "../Currency";

const LineItemStyle = styled.div`
    display:grid;
    grid-template-columns:repeat(2,1fr);
    .price {
        text-align:right;
    }
    line-height:1;
    align-items: center;
    @media (max-width: 768px) {
        grid-template-columns: 3fr 1fr !important;
    }
`;

const LineItem = ({onChange,amount,applied,disabled}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    const toggleCheck = () => {
        onChange(!applied);
    }

    return (
        <LineItemStyle>
            <div>
                <label>{localization.protection_coverage}</label>
                <Slider disabled={disabled} onClick={toggleCheck} applied={applied}/>
            </div>
            <div className="price"><Currency amount={applied ? amount : undefined}/></div>
        </LineItemStyle>
    )
}

export default LineItem
