import React from "react"
import SiteContext from '~/src/context/Site';
import DocumentContext from '~/src/context/Document'
import localization from "~/src/static/localization/confirmation/move_in"

const Unsuccessful = () => {
    const { language } = React.useContext(SiteContext)
    const { contact_data } = React.useContext(DocumentContext)
    const { first_name } = contact_data.client_information

    localization.setLanguage(language)
    let labels = localization.unsuccessful.move_in_message.default
    let paragraph_lines = labels.paragraph.map((text,index) => {
        if (index != 0) return <React.Fragment key={index}><br/>{text}</React.Fragment>
        else return <React.Fragment key={index}>{text}</React.Fragment>
    })

    let header_message = localization.formatString(labels.header,{first_name})
    return (
        <React.Fragment>
            <h1>{header_message}</h1>
            <p>{paragraph_lines}</p>
        </React.Fragment>
    )
}

export default Unsuccessful