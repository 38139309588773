import LocalizedStrings from 'react-localization';

let AUTHENTICATION_LOCALIZATION = new LocalizedStrings({
 english:{
    title:"Express Rent Now",
    id:"Reference ID",
    email:"Email Address",
    back_to_email_cta: "To continue the Express Rent Online process, return to your reservation email and click on the 'Rent Now' button.",
  },
  french: {
  }
});

export default AUTHENTICATION_LOCALIZATION