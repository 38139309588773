import React from "react"
import { useHistory, useLocation } from "react-router";
import styled from "styled-components"
import tokenValid from "~/src/lib/tokenValid"
import SiteContext from "~/src/context/Site"

const Wrapper = styled.div`
    width:100%;
    height:20vh;
    padding:10vh;
    text-align:center;
`;

const Page404 = () =>{
    const {setAuthToken:setToken} = React.useContext(SiteContext)
    const history = useHistory();
    let location = useLocation();

    //deprecated
    React.useEffect(() => {
        try{
            token = location.pathname.substring(1)
            if (tokenValid(token)) setToken(token)
            history.push("/")
        }catch(e){
            console.log(e)
        }
    },[])

    return (
        <Wrapper>
            <h1>404</h1>
        </Wrapper>
    )
}

export default Page404