import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english: {
        next: "Next >>",
        submit: "Rent Now >>",
        previous: "<< Previous",
    },
    french: {
        next: "Suivant >>",
        submit: "Louer maintenant >>",
        previous: "<< Précédent",
    }
});

export default LOCALIZATION