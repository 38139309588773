import React from "react";

import StandardLineItem from "./StandardLineItem";
import DocumentContext from "~/src/context/Document"
import SiteContext from "~/src/context/Site"
import selectComponent from "./selectComponent"
import styled from "styled-components";
import localization from "~/src/static/localization/common/rental_details/line_items"
import { useProtectionPlan } from "../../../../lib/useProtectionPlan";

const defaultGrey = '#666';
const defaultFont = "'Hind', 'Arial', sans-serif";
const LineItemsStyle = styled.div`
  color: ${defaultGrey};
  font-family: ${defaultFont};
  padding: 1rem 0;

  .unit-name {
    font-weight: bold;
  }
`;

const HrStyle = styled.hr`
  border-top: 3px solid #E3E3E3;
`;

const HrHalfStyle = styled.hr`
  border-top: 3px solid #E3E3E3;
  width: calc(50% - 0.5em);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LineItems = ({show_asterisk = true , display_protection = true, display_popup = true, onChangeAcknowledgement}) => {
    const {pricing,quote} = React.useContext(DocumentContext)
    const {language} = React.useContext(SiteContext)
    const [unit_size, setUnit_size] = React.useState("")
    const [list_items,setList_items] = React.useState([])
    const protectionLineItems = useProtectionPlan()
    let {sub_total, subTotal, tax, total, line_items = []} = pricing ? pricing : {};
    localization.setLanguage(language)

    React.useEffect(() => {
        let temp_line_items = line_items
        if (quote) {
            let newUnitSize = `${quote.unit_width}'x${quote.unit_length}'`
            setUnit_size(newUnitSize)

          //Add the two additional line items for protection pricing
          temp_line_items = [
            ...(line_items.filter(item => item.type !== 'admin_fee')),
            ...(quote.offer_protection_plan !== false? protectionLineItems:[]),
            ...(line_items.filter(item => item.type === 'admin_fee'))
          ]
        }

        setList_items(temp_line_items.map(
            (data, index) => selectComponent(data, index, display_protection, display_popup, onChangeAcknowledgement,show_asterisk)
        ))
    }, [quote, protectionLineItems])

    const style = display_popup ? {gridTemplateColumns: 'repeat(4,1fr)'} : ""
    const bold_style = display_popup ? {gridTemplateColumns: 'repeat(4,1fr)', fontWeight: 'bold'} : {fontWeight: 'bold'}

    return (
        <LineItemsStyle>
            <div className="unit-name">{localization.formatString(localization.storage_unit, unit_size)}</div>
            {list_items}
            {display_popup ? <HrHalfStyle/> : <HrStyle/>}
            <StandardLineItem style={style} type={"sub_total"} amount={sub_total || subTotal} always_show={true}/>
            <StandardLineItem style={style} type={"tax"} amount={tax} always_show={true}/>
            {display_popup ? <HrHalfStyle/> : <HrStyle/>}
            <StandardLineItem type={"total"} amount={total} always_show={true} style={bold_style}/>
        </LineItemsStyle>
    )
}

export default LineItems
