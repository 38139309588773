import React from "react"
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

const Green = "#04BE45"
const AccordionToggle = styled(Accordion.Toggle)`
    display: flex;
    justify-content:space-between;
    img {
        height:20px;
    }
    h2 {
        font-size:1rem;
        font-weight:bold;
        margin:0;
    }
    h2.completed {
        font-weight:normal;
    }
    span.completed {
        color:${Green};
        visibility: visible;
        font-size: 1.5em;
        font-weight: 900;
        line-height: .75em;
    }
`;

const Banner = ({completed,title}) => {
    //TODO remove class names and use styled components
    return (
        <AccordionToggle as={Card.Header} variant="link">
            <h2 className={completed ? "completed" : ""}>{title}</h2>
            {completed && <span className="completed">✓</span>}
        </AccordionToggle>
    )
}

export default Banner