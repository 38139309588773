import React from "react";
import styled from "styled-components";
import Overlay from "react-bootstrap/Overlay"

const InfoIcon = styled.div`
    background:#666;
    color:white; 
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    font-size:10px;
    height:14px;
    width:14px;
    text-align:center;
    vertical-align:middle;
    margin:0 3px;
    display:inline-block;
    cursor: pointer;
`;

const InfoOverlay = ({content}) => {
    const [show, setShow] = React.useState(false);
    const target = React.useRef(null);
    
    return (
        <React.Fragment>
            <InfoIcon ref={target} onMouseEnter={() => setShow(!show)} onMouseLeave={() => setShow(!show)}>i</InfoIcon>&nbsp;
            <Overlay target={target.current} show={show}
            placement="right">
            {({ placement, offset, arrowProps, show: _show, popper, ...props }) => (
                <div
                 
                {...props}
                style={{
                    backgroundColor: '#707070',
                    padding: '2px 8px',
                    color: 'white',
                    borderRadius: 3,
                    maxWidth:'220px',
                    fontSize:'0.75em',
                    borderLeft: '3px solid #fff',
                    ...props.style,
                }}
                >
                {content}
                </div>
            )}
            </Overlay>
        </React.Fragment>
    );
}

export default InfoOverlay