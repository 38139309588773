import React from "react";
import styled from "styled-components";

import SiteContext from "~/src/context/Site";
import BrandLogo from "~/src/components/common/assets/BrandLogo";
import LanguageToggle from "~/src/components/common/LanguageToggle"
import StepsBar from "./reservation/StepsBar/Main";

const HeaderBanner = styled.header`
    width:100%;
    padding:20px;
    .logo {
        height:64px;
    }
    display:flex;
    justify-content:space-between;
    align-items:center;
`;

const Header = () => {
    const {brandInfo:{url,logo,name}} = React.useContext(SiteContext);
    return (
        <React.Fragment>
            <HeaderBanner className="container">
                <BrandLogo url={url} logo={logo} name={name} />
                <LanguageToggle />
            </HeaderBanner>
        </React.Fragment>
    );
}

export default Header