import React from "react"
import styled from "styled-components"
import SiteContext from "~/src/context/Site"
import formatPhoneNumber from '../../lib/formatPhoneNumber';

const defaultGrey = '#666';
const defaultFont = "'Hind', 'Arial', sans-serif";
const defaultDivider = "#E3E3E3"
const StoreStyle = styled.div`
    border-top:3px solid #E3E3E3;
    margin:0;
    padding:1rem 0;
    color:${defaultGrey};
    font-family:${defaultFont};
    .border-top{
        border-top:3px solid ${defaultDivider};
    }
    .border-bottom{
        border-bottom:3px solid ${defaultDivider};
    }
`;

const Store = ({ childClass }) => {
    const { facilityData } = React.useContext(SiteContext)

    if (facilityData == undefined) return null

    let {
        address,
        brand,
        name,
        phone,
        email = "reservations@storagevaultcanada.com"
    } = facilityData

    let address_str = `
        ${address.street_1 + (address.street_2 ? " " + address.street_2 : "")},
        ${address.city},
        ${address.province},
        ${address.postal_code}
    `
    const phoneClean = `tel:${phone.replace(/^/, '+1').replace(/-/g, "")}`;
    return (
        <StoreStyle className={childClass}>
            <div className="font-weight-bold">{brand ? `${brand} - ` : ''}{name}</div>
            <div className="address">{address_str}</div>
            <div className="contact_info">
                <div>{email}</div>
                <div>{formatPhoneNumber(phoneClean)}</div>
            </div>
        </StoreStyle>
    )
}

export default Store