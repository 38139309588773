import React from "react"
import styled from "styled-components"
import SiteContext from "~/src/context/Site"
import DocumentContext from "~/src/context/Document"
import localization from "~/src/static/localization/common/rental_details/line_items"
import Currency from "./Currency"

const DiscountLineItemStyle = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1em;
    @media (max-width: 768px) {
        grid-template-columns: 3fr 1fr !important;
    }
    label {
        margin:0;
    }
    div:last-child{
        text-align:right;
    }
`

const DiscountLineItem = ({type, amount, style,show_asterisk}) => {
    const {quote} = React.useContext(DocumentContext)
    const {language} = React.useContext(SiteContext)
    const [subtext,setSubtext] = React.useState("")
    localization.setLanguage(language)
    const {label} = type ? localization.line_items[type] : {label: "unknown"}

    React.useEffect(()=>{
        if (quote){
            let num_weeks,percentage,subtext,asterisk
            asterisk = show_asterisk? "*" : "" 
            num_weeks = quote.discount.length * 4
            percentage = quote.discount.percentage
            let selected_text = (percentage < 100)? localization.discount.description.standard:localization.discount.description.free;
            setSubtext(localization.formatString(selected_text,{num_weeks,percentage,asterisk}))
        }
    },[quote, language])

    return (
        <DiscountLineItemStyle style={{...style}}>
            <div>
                <label>{label}</label> {subtext}
            </div>
            <div>
                <Currency amount={amount}/>
            </div>
        </DiscountLineItemStyle>
    )
}

export default DiscountLineItem