import React from "react";
import styled from "styled-components";
import localization from "~/src/static/localization/common/form_section"
import SiteContext from '~/src/context/Site'
import Spinner from "react-bootstrap/Spinner"
const NextButtonStyle = styled.button`
    background:${props => props.bgcolor || "#04BE45"};
    color:white;
    border: 1px solid transparent;
    padding: 0.70rem 3.5rem;
    margin:20px 0;
    font-size: 1.5rem;
    line-height: 1;
    border-radius: 0.5rem;
    &:hover {
        background:${props => props.bgcolor || "#04BE45"};
        color:white;
    }
    @media (max-width: 768px) {
        width: 48%;
        font-size: 1.2rem;
        padding: 0.70rem 0;
    }
`;

const Child = ({children,text,isLoading}) => {
    if (isLoading) return <Spinner animation="border"/>
    if (children) return <React.Fragment>{children}</React.Fragment>
    return <React.Fragment>{text}</React.Fragment>
}
const NextButton = ({children,onClick,type,bgcolor,direction=true,submit=false,disabled=false,isLoading=false}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    let text = ""
    if (submit) text = localization.submit
    else if (direction) text = localization.next
    else text = localization.previous

    return (
        <NextButtonStyle type={type} bgcolor={bgcolor} onClick={onClick} disabled={disabled}>
            <Child children={children} text={text} isLoading={isLoading}/>
        </NextButtonStyle>
    )
}
export default NextButton;