import React from "react"
import Successful from "./Successful"
import SiteContext from '~/src/context/Site';
import styled from "styled-components"
import Unsuccessful from "./Unsuccessful";
import Spinner from 'react-bootstrap/Spinner'

const MessageStyle = styled.div`
    color:#666;
    font-family:'Hind', 'Arial', sans-serif;
    margin:30px 0;
    padding:30px 50px;
    border:1px solid #008AE0;
    border-radius:5px;
    text-align:center;
    background: #fff;
    box-shadow: 3px 6px 15px rgba(0,0,0,.15);
    @media (max-width: 768px) {
        padding: 20px 30px;
    }
    h1 {
        font-size:2rem;
        margin-bottom:25px;
        font-weight:bold;
        text-align:center;
    }
    p:last-child{
        margin-bottom:0;
    }
    img{
        margin-left: 4px;
        width: 40px;
    }
`;

let Message = ({successful}) => {
    switch(successful){
        case true: return <Successful/>
        case false: return <Unsuccessful/>
        default: return <Spinner animation="border"/>
    }
}

const MoveInMessage = () => {
    const {isMoveInSuccessful,redirectedToConfirmation} = React.useContext(SiteContext)

    if (redirectedToConfirmation) return null

    return (
        <MessageStyle>
            <Message successful={isMoveInSuccessful}/>
        </MessageStyle>
    )
}

export default MoveInMessage