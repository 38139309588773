let messages = {
    english:{
        success: {
            title: "Success!",
            message: `You have successfully accepted the terms and conditions of the {formName}.`,
            type: "success", 
        },
        acknowledgement_missing: {
            title:"Acknowledgement Not Signed",
            message:"Acknowledgement not accepted. In order to proceed, please check the box to accept the terms and conditions.",
        }

    },
    french: {
        success: {
            title: "Succès!",
            message: `Vous avez accepté avec succès les conditions de {formName}.`,
            type: "success",
        },
        acknowledgement_missing: {
            title:"Confirmation non signée",
            message:"La confirmation n’est pas acceptée. Pour continuer, veuillez cocher la case pour accepter les conditions.",
        }
    }
}

export default messages
