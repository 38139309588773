import React from "react"
import styled from "styled-components"
import SiteContext from "~/src/context/Site";
import { localization } from "~/src/static/localization/common/ErrorBoundary"

const ErrorStyle = styled.div`
    text-align:center;
`;
export const ErrorMessage = () => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    const messages = localization.messages.map((message,index)=>{
        return <p key={index}>{message}</p>
    })
    return (
        <ErrorStyle>
            <h1>{localization.header}</h1>
            {messages}
        </ErrorStyle>
    )
}