import LocalizedStrings from 'react-localization';
import title from "./title"
import client_information from "./client_information"
import payment_information from "./payment_information"
import additional_information from "./additional_information"

let LOCALIZATION = new LocalizedStrings({
    english:{
        title: title.english,
        client_information: client_information.english,
        payment_information: payment_information.english,
        additional_information: additional_information.english
    },
    french: {
        title: title.french,
        client_information: client_information.french,
        payment_information: payment_information.french,
        additional_information: additional_information.french
    }
});

export default LOCALIZATION