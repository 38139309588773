import React from "react"
import SiteContext from '~/src/context/Site';
import localization from "~/src/static/localization/confirmation/move_in"
import ShieldIcon from '../../../../static/images/icons/shield.svg'
import {useHistory} from 'react-router-dom';
import NextButton from "~/src/components/common/NextButton";
import ModalLearnMore from './ModalLearnMore';
import {analytics} from "~/src/lib/analytics"

const Successful = () => {
    const {brandInfo:{shop_url}, language,identityVerificationCompleted} = React.useContext(SiteContext)
    localization.setLanguage(language)
    let messages = localization.successful.move_in_message
    const [labels,setLabels] = React.useState(messages.default)
    let history = useHistory();

    React.useEffect(()=>{
        if (identityVerificationCompleted) setLabels(messages.identity_verification_completed)
    },[identityVerificationCompleted])

    const [showPopup, setShowPopup] = React.useState(false)
    const onPopupShow = () => setShowPopup(true)

    let paragraphs_lines = labels.paragraph.map((text,index)=> (
        <React.Fragment key={index}>
            {text}
            { index != (labels.paragraph.length - 1) && <br/>}
        </React.Fragment>
    ))

    let cta_click = () => {
        if (identityVerificationCompleted) {
            switch (language){
                case "french":{
                    window.open(shop_url, "_blank");
                    break;
                }
                default: window.open(shop_url, "_blank");
            }
        }
        else (history.push('/identity_verification'))

        analytics.event({
            category: "Move-In Confirm ID",
            action: "Click",
            label: (identityVerificationCompleted) ? "Verification Complete" : "Confirm ID Now"
        })
    }

    return (
        <React.Fragment>
            <h1>
                {labels.header}
                {!identityVerificationCompleted && <img src={ShieldIcon} alt="SVI Security" />}
            </h1>
            <p>
                {paragraphs_lines}
                {!identityVerificationCompleted && <a href="#" onClick={onPopupShow} > {labels.learnMore}</a>}
            </p>
            <NextButton onClick={cta_click}>{labels.cta}</NextButton>
            {(showPopup) && <ModalLearnMore setShowPopup={setShowPopup}/>}
        </React.Fragment>
    )
}

export default Successful