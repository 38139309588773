import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english: {
        successful: {
            move_in_message:{
                identity_verification_completed:{
                    header: "You are all done!",
                    paragraph: [
                        "Within the next few minutes, a Storage Advisor will reach out to provide an overview of the move-in process and schedule your first visit.",
                        "Moving boxes and packing supplies can be ordered online or over the telephone to be delivered contact-free to your storage unit or for curbside pickup.",
                    ],
                    cta:"Order Boxes >>",
                },
                default:{
                    cta: "Confirm ID Now >>",
                    header: "Confirm Your Identity",
                    paragraph: [
                        "An important final step.",
                        "For your peace of mind, we verify all client identity and follow strict security standards to protect your information.",
                        " It’s safe, convenient and easy to use.",
                    ],
                    learnMore: "Learn More",
                },
            },
            details: {
                messages:[
                    "Your storage rental is complete.  Your payment has been processed and your details have been forwarded to our team.",
                    "Within the next few minutes, you will receive an email with your move-in documents and a friendly Storage Advisor will call to provide an overview of the move-in process and schedule your first visit.",
                    "Please note that all rental requests made after office hours will be processed the following morning."
                ],
                header: "Your Rental Details",
            }
        },
        unsuccessful: {
            move_in_message:{
                default:{
                    header: "{first_name}, your reservation is confirmed!",
                    paragraph: [
                        "However, we were unable to finalize your storage rental.",
                        "",
                        "Your rental details have been forwarded to our team. Within the next few minutes, you will receive an email with your move-in documents and a friendly Storage Advisor will call to process your payment and schedule your first visit.",
                        "",
                        "Please note that all rental requests made after office hours will be processed the following morning."
                    ]
                }
            },
            details: {
                header:"Your Reservation Details",
            }
        },
        details: {
            confirmation_number: "Your Confirmation Number:",
            move_in_date: "Move-in Date:"
        },
        modal: {
            header: "Security you can trust.",
            paragraph_1: "As an added security protocol, we confirm all client identity.",
            paragraph_2: "To confirm your identity, you’ll be asked to take a photo of the front and back side of your valid, government issued ID (driver’s licence, passport, or national identity card). Additionally, you will be asked to take a new photo of yourself which matches the photo on your ID.",
        }
    },
    french: {
        successful: {
            move_in_message:{
                identity_verification_completed:{
                    header: "Vous avez terminé!",
                    paragraph: [
                        "Dans les prochaines minutes, un conseiller en entreposage vous contactera pour vous donner un aperçu du processus d’emménagement et planifier votre première visite.",
                        "Des boîtes de déménagement et des fournitures d'emballage peuvent être commandées en ligne ou par téléphone et être livrées sans contact à votre unité d’entreposage ou être ramassées à l’auto.",
                        "Veuillez noter que toutes les demandes de location effectuées après les heures d’ouverture seront traitées le lendemain matin."
                    ],
                    cta:"Commander des boîtes >>",
                },
                default:{
                    cta: "Confirmer son identité maintenant >>",
                    header: "Confirmez votre identité",
                    paragraph: [
                        "Une dernière étape importante.",
                        "Pour votre tranquillité d’esprit, nous vérifions l’identité de tous les clients et suivons des normes de sécurité strictes pour protéger vos renseignements. Un processus sécuritaire, pratique et convivial!",
                        "Un processus sécuritaire, pratique et convivial! En savoir plus."
                    ],
                    learnMore: "En savoir plus",
                },
            },
            details: {
                messages:[
                    "Le processus de location de votre espace d’entreposage est terminé. Votre paiement a été traité et vos détails ont été transmis à notre équipe.",
                    "Dans les prochaines minutes, vous recevrez un courriel comprenant vos documents d’emménagement, et un sympathique conseiller en entreposage vous appellera pour vous donner un aperçu du processus d’emménagement et planifier votre première visite.",
                    "Veuillez noter que toutes les demandes de location effectuées après les heures d’ouverture seront traitées le lendemain matin.",
                ],
                header: "Vos détails de location",
            }
        },
        unsuccessful: {
            move_in_message:{
                default:{
                    header: "{first_name}, votre réservation est confirmée!",
                    paragraph: [
                        "Cependant, nous n’avons pas réussi à finaliser la location de votre espace.",
                        "",
                        "Vos détails de location ont été transmis à notre équipe. Dans les prochaines minutes, vous recevrez un courriel comprenant vos documents d’emménagement, et un sympathique conseiller en entreposage vous appellera pour traiter votre paiement et planifier votre première visite.",
                        "",
                        "Veuillez noter que toutes les demandes de location effectuées après les heures d’ouverture seront traitées le lendemain matin."
                    ]
                }
            },
            details: {
                header:"Vos détails de réservation ",
            }
        },
        details: {
            confirmation_number: "Your Confirmation Number:",
            move_in_date: "Date d’emménagement :"
        },
        modal: {
            header: "Un processus de sécurité fiable.",
            paragraph_1: "Comme protocole de sécurité supplémentaire, nous confirmons l’identité de tous les clients.",
            paragraph_2: "Afin de confirmer votre identité, vous devrez prendre une photo du côté avant et arrière de votre pièce d’identité valide délivrée par le gouvernement (permis de conduire, passeport ou carte d’identité nationale). En outre, vous devrez vous photographier et votre nouvelle photo devra correspondre à celle qui figure sur votre pièce d’identité.",
        }
    },
});

export default LOCALIZATION