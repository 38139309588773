import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router";

import DocumentContext from '~/src/context/Document'
import SiteContext from "../../context/Site"
import StorageSteps from "./StorageSteps"
import RentalDetails from "./RentalDetails"
import HoursOfOperation from "~/src/components/common/assets/HoursOfOperation"
import FinePrint from "~/src/components/common/assets/FinePrint"
import {Introduction} from "./Introduction"

const defaultGrey = '#666';
const defaultFont= "'Hind', 'Arial', sans-serif";

const LandingStyle = styled.div`
    color:${defaultGrey};
    font-family:${defaultFont};
    padding:0;
    h1, h2, h3, h4 {
        font-weight:bold;
    }
`;

const Landing = () => {
    const {quote,pricing} = React.useContext(DocumentContext)
    const {facilityData,setRedirectedToConfirmation} = React.useContext(SiteContext)
    const history = useHistory()

    React.useEffect(()=>{
        if (quote && quote.ero_completed) {
            setRedirectedToConfirmation(true)
            history.push("/reservation/confirmation")
        }
    },[quote])

    if (facilityData) return (
        <LandingStyle className="landing">
            <Introduction/>
            <div className="container">
                <StorageSteps/>
                <RentalDetails quote={quote} pricing={pricing}/>
                {facilityData && 
                    <HoursOfOperation
                        store={facilityData.hours.facility}
                        gate={facilityData.hours.unit}
                    />
                }
                <FinePrint />
            </div>
        </LandingStyle>
    )
    else return null
}

export default Landing