let contract_name = {
    english:{
        // client_information:"Client Information Form", //TODO: Remove this
        // payment_authorization:"Payment Authorization Form", //TODO: Remove this
        contact_information_form:"Client Information Form",
        payment_information_form:"Payment Authorization Form",
        // user_agreement:"User Agreement",
        // user_agreement_protection_plan_offered:"User Agreement",
        // user_agreement_protection_plan_not_offered:"User Agreement",
        // certificate_of_protection:"Certificate of Protection",
        // protection_plan_exemption_form:"Protection Plan Exemption Form",
    },
    french: {
        // client_information:"Formulaire de renseignements sur le client",
        // payment_authorization:"Formulaire d'autorisation de paiement",
        contact_information_form:"Formulaire de renseignements sur le client",
        payment_information_form:"Formulaire d'autorisation de paiement",
        // user_agreement:"Entente sur les conditions d’utilisation",
        // user_agreement_protection_plan_offered:"Entente sur les conditions d’utilisation",
        // user_agreement_protection_plan_not_offered:"Entente sur les conditions d’utilisation",
        // certificate_of_protection:"Certificat de protection",
        // protection_plan_exemption_form: "Formulaire d’exemption du plan de protection",
    }
};

export default contract_name