import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import SiteContext from '~/src/context/Site';
import localization from "~/src/static/localization/confirmation/move_in"
import ShieldIcon from '../../../../static/images/icons/shield.svg'

const ContentStyle = styled.div`
    font-family:'Hind', 'Arial', sans-serif;
    color: #666;
    margin: 40px 80px;
    text-align: center;
    background: #fff;

    h1 {
        font: normal normal bold 30px/26px 'Lucida Grande';
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
    }
    p{
        font-family: 'Hind', 'Arial', sans-serif;
        font-size: 18px;
        padding-bottom: 15px;
    }
    img{
        width: 75px;
        margin: 8px;
    }
    button{
        font-family: 'Arial', sans-serif;
        border: 1px solid #666666;
        width: 40px;
        border-radius: 20px;
        float: right;
        margin: -30px -70px 0 0;
        font-size: 40px;
        font-weight: 100;
    }
`;

const ModalLearnMore = ({ setShowPopup }) => {
    const { language } = React.useContext(SiteContext)
    localization.setLanguage(language)
    let labels = localization.modal

    const closePopup = () => setShowPopup(false)

    return (
        <Modal show={true} size="lg">
            <Modal.Body>
                <ContentStyle>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closePopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h1><img src={ShieldIcon} alt="SVI Security" /> {labels.header}</h1>
                    <p>{labels.paragraph_1}</p>
                    <p>{labels.paragraph_2}</p>
                </ContentStyle>
            </Modal.Body>
        </Modal>
    )
}

export default ModalLearnMore