import LocalizedStrings from 'react-localization';

let LOCALIZATION = new LocalizedStrings({
    english: {
        title: "Confirm Your Identity",
        subtitle: "As an added security protocol, we confirm all client identity.",
        content: "To confirm your identity, you'll be asked to take a photo of the front and back side of your valid, government issues ID (driver's licence, passport, or national identity card). Additionally, you will be asked to take a new photo of yourself which matches the photo on your ID.",
        option_one: {
            header: "Use your smartphone",
            desc_one: "This is the easiest and more accurate way to provide your ID documents.",
            desc_two: "Using your smartphone camera, scan the QR code to start.",
        },
        option_two: {
            header: "Use your computer or tablet",
            desc_one: "Some desktop and laptop cameras take low quality images which might be insufficient for ID verification.",
            desc_two: "When possible, we recommend using a mobile phone camera.",
            start: "Start",
        },
        yoti_info: {
            title: "We use our identity provider Yoti to confirm your identity",
            learn_more: "Find out more at "
        },
    },
    french: {
        title: "Confirmez votre identité",
        subtitle: "Comme protocole de sécurité supplémentaire, nous confirmons l’identité de tous les clients.",
        content: "Afin de confirmer votre identité, vous devrez prendre une photo du côté avant et arrière de votre pièce d’identité valide délivrée par le gouvernement (permis de conduire, passeport ou carte d’identité nationale). En outre, vous devrez vous photographier et votre nouvelle photo devra correspondre à celle qui figure sur votre pièce d’identité.",
        option_one: {
            header: "Utilisez votre téléphone intelligent",
            desc_one: "Il s’agit de la façon la plus simple et efficace de fournir vos documents d’identité.",
            desc_two: "À l'aide de l'appareil photo de votre téléphone intelligent, balayez le code QR pour commencer.",
        },
        option_two: {
            header: "Utilisez votre ordinateur ou votre tablette",
            desc_one: "Certains appareils photo de bureau et de portables prennent des images de qualité inférieure, qui peut être insuffisante pour la vérification de l’identité.",
            desc_two: "Lorsque cela est possible, nous vous recommandons d’utiliser l’appareil photo d’un téléphone cellulaire.",
            start: "Démarrer",
        },
        yoti_info: {
            title: "Nous utilisons notre fournisseur d’identité Yoti pour confirmer votre identité",
            learn_more: "Pour en savoir plus, visitez Yoti.com"
        }
    },
});

export default LOCALIZATION