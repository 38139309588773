const payment_information = {
    english: {
        title: "Rental Details",
        header: "Add Payment Method",
        subheader: "Credit Card Authorization",
        legal_authorization_text: `
        The cardholder hereby authorizes the Owner to charge all User Fees and any and all other charges and taxes payable under the terms of the User Agreement for each Rental Period for the entire term of the occupancy of the self storage Space. All amounts may be adjusted from time to time by the Owner as noted in the User Agreement.
        `,
        card: {
            holder_name: "Name on Card",
            number: "Credit Card Number",
            expiry: "Expiration Date",
            security_code: "CVV",
            month_default: "MM",
            year_default: "YY",
        },
        acknowledgement: "I, {first_name} {last_name}, hereby accept the terms and conditions stated above.",
        error_message: {
            acknowledgement_missing: {
                title: "Acknowledgement Not Signed",
                message: "Acknowledgement not accepted.  In order to proceed, please check the box to accept the terms and conditions.",
            },
            protection_plan_missing: {
                title: "Protection plan alternative not selected",
                message: "Protection plan alternative not selected. In order to proceed, please select an option.",
            },
            protection_declined: {
                title: "Protection Plan Requires Requires Reason",
                message: "Please provide a reason to declining protection plan",
            }
        },
    },
    french: {
        title: "Détails de la location",
        header: "Mode de paiement",
        subheader: "Autorisation de carte de crédit",
        legal_authorization_text: `
            Par la présente, le titulaire de la carte autorise le propriétaire à facturer tous les frais d’utilisation et tous les autres frais et taxes payables en vertu des termes de l’entente sur les conditions d’utilisation pour chaque période de location pendant toute la durée de l’occupation de l’espace d’entreposage libre-service. Tous les montants peuvent être ajustés de temps à autre par le propriétaire comme indiqué dans l’entente sur les conditions d’utilisation.`,
        card: {
            holder_name: "Nom sur la carte",
            number: "Numéro de carte de crédit",
            expiry: "Date d’expiration",
            security_code: "CVV",
            month_default: "MM",
            year_default: "AA",
        },
        acknowledgement: "Je, {first_name} {last_name}, accepte par la présente les conditions énoncées ci-dessus.",
        error_message: {
            acknowledgement_missing: {
                title: "Confirmation non signée",
                message: "La confirmation n’est pas acceptée. Pour continuer, veuillez cocher la case pour accepter les conditions.",
            }
        },
    },
}

export default payment_information
