import regeneratorRuntime from "regenerator-runtime";
import {buildConfig} from "./lib"
import Axios from "axios"
if (!process.env.ERO_BACKEND_URL) throw "Environment Variable 'ERO_BACKEND_URL' does not exist or is undefined"

/**
* Sends contract details to the backend server
* @param {Object} contractDetails - The completed form from reservations component
* @param {String} authToken - JWT token stored in memory
* @return {String} Reference code
*/
export const postReservationContact = async (contact_information,authToken) => {
    let config = buildConfig(authToken);
    let url = `${process.env.ERO_BACKEND_URL}/reservation/contact_information`;
    let response = await Axios.post(url,contact_information,config);
    return response.data;
}