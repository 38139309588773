import React from "react"
import styled from "styled-components"
import localization from "~/src/static/localization/landing/hours"
import SiteContext from "~/src/context/Site"

const HoursOfOperationStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: auto;
    gap: 1em;
    grid-template-areas:
        ". ."
        "store gate";
    h3 {
        font-size:1.8rem;
        font-weight:bold;
        @media (max-width: 768px) {
            font-size:1.2rem;
        }
    }
    color:#666;
    margin:50px 0;
    @media (max-width: 768px) {
        zoom: 90%;
    }
`;

const LineItem = styled.span`
    display:grid;
    grid-template-columns:repeat(2,1fr);

    > * {
        vertical-align:middle;
        margin-bottom:0;
    }
    .day {
        text-transform:capitalize;
    }
`;

const Hours = ({localization,className,data}) => {
    const HoursOfOperations = Object.entries(data).map(([key,value],index) => {
        let displayValue = value == "Open 24 hours"? localization.open_24_hours:value
        displayValue = displayValue == null? localization.closed:displayValue
        return (
            <LineItem key={index}>
                <p className="day">{localization[key]}</p>
                <p>{displayValue}</p>
            </LineItem>
        )
    });
    return (
        <div className={className}>
            {HoursOfOperations}
        </div>
    )
}

const defaultHours = {
    monday      : "8am to 10pm",
    tuesday     : "8am to 10pm",
    wednesday   : "8am to 10pm",
    thursday    : "8am to 10pm",
    friday      : "8am to 10pm",
    saturday    : "9am to 7pm",
    sunday      : "9am to 6pm",
}

const HoursOfOperation = ({
        store = defaultHours,
        gate = defaultHours}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    return (
        <HoursOfOperationStyle>
            <h3>{localization.title_store}</h3>
            <h3>{localization.title_facility}</h3>
            <Hours localization={localization} className="store" data={store}/>
            <Hours localization={localization} className="gate" data={gate}/>
        </HoursOfOperationStyle>
    )
}

export default HoursOfOperation