import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import LineItem from "./LineItem";
import PopUpAccepted from "./PopUpAccepted";
import PopUpRejected from "./PopUpRejected/Main";
import DocumentContext from "~/src/context/Document"
import StandardLineItem from "../StandardLineItem";
import {analytics} from "~/src/lib/analytics"

const ProtectionCoverrageStyle = styled.div`
    display:grid;
    grid-template-areas: "lineitem popup";
    grid-template-columns: 1fr 1fr;
    gap:1em;
    position: relative;
    @media (max-width: 768px) {
        display: block;
    }
    .popup {
        grid-area: popup;
    }
    .lineitem {
      grid-area: lineitem;
    }
`;

const HrHalfStyle = styled.hr`
    border-top:3px solid #E3E3E3;
    width:calc(50% - 0.5em);
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ProtectionCoverage = ({ amount, display_popup, style, onChangeAcknowledgement}) => {
    const {contact_data,pricing,setPricing} = React.useContext(DocumentContext)
    let [disableSelection,setDisableSelection] = React.useState(false)
    let {protection_plan} = pricing? pricing:{}
    const toggle = (value) => {
        setPricing({
            ...pricing,
            protection_plan:{
                ...protection_plan,
                applied: value,
            }
        })
        if (onChangeAcknowledgement) onChangeAcknowledgement();

        analytics.event({
            category: "Add Payment Method",
            action: "Toggle",
            label: "Protection Plan"
        })
    };

    React.useEffect(() =>{
        let {stored_items_type} = contact_data.additional_information
        if (stored_items_type === "vehicle") {
            setDisableSelection(true)
            toggle(false)
        }
    },[contact_data])

    if (!protection_plan) return null
    if (display_popup && protection_plan) return (
        <Fragment>
            <HrHalfStyle />
            <ProtectionCoverrageStyle>
            <LineItem   className="lineitem"
                        onChange={toggle}
                        amount={amount}
                        applied={protection_plan.applied}
                        disabled={disableSelection}/>
            {protection_plan.applied? <PopUpAccepted className="popup" /> : <PopUpRejected className="popup" onChangeAcknowledgement={onChangeAcknowledgement} />}
            </ProtectionCoverrageStyle>
        </Fragment>
    )
    else return (
        <StandardLineItem   type="protection"
                            amount={amount}
                            applied={protection_plan.applied} style={style}/>
    )
}

export default ProtectionCoverage