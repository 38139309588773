import React, {useRef} from "react"
import styled from "styled-components";
import InfoOverlay from "~/src/components/common/InfoOverlay";
import InputMask from "react-input-mask";
import SiteContext from '~/src/context/Site';
import {browserErrorMessages} from "~/src/static/localization/validation";

export const FormFieldStyle = styled.label`
    display:block;
    font-size: 1em;
    font-weight: 500;
    color:#666;
    
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff;
        color: #666;
        border:1px solid rgba(102,102,102,0.7);
        width:100%;
        border-radius: 4px;
        margin-top: 0.25em;
        padding: 0.3em 2em 0.3em 0.3em;
        font-size: 1em;
    }
    .select-container {
        position:relative; 
        display: inline;
    }
    .select-container:after {
        content: ""; 
        width: 0; 
        height: 0; 
        position: absolute; 
        pointer-events: none;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: .3em;
        right: .75em;
        border-top: 8px solid rgba(102,102,102);
    }
    select::-ms-expand {
        display: none;
    }

    input {
        display:block;
        font-size:1em;
        width:100%;
        font-weight: normal;
        border-radius: 4px;
        padding: 0.3em;
        margin-top: 0.25em;
        border:1px solid rgba(102,102,102,0.7);
        color:#666;
    }
    
    input:read-only {
        background-color: #EFEFEF;
    }
`;

const FormField = ({
                       className,
                       label,
                       type,
                       onChange,
                       formData,
                       name,
                       custom,
                       children,
                       info,
                       required = false,
                       requiresDecode = false
                   }) => {

    const {language} = React.useContext(SiteContext)
    browserErrorMessages.setLanguage(language)

    const selectRef = useRef();

    //show custom error on form submit
    const customValidity = (e) => {
        e.target.setCustomValidity(browserErrorMessages.default)
    }

    //update to relevant error as input as user enters data, or else remove error
    const checkValidity = (e) => {
        if (e.target.validity.patternMismatch) {
            e.target.setCustomValidity(browserErrorMessages.wrong_format)
        } else e.target.setCustomValidity('');
    }

    if (children != undefined) {
        return (
            <FormFieldStyle>
                {label}
                {children}
            </FormFieldStyle>
        )
    } else {
        let value = ""
        try {
            value = requiresDecode ? window.atob(formData[name]) : formData[name];
        } catch (e) {
            console.log(`Initial Value was not set, please add this field. Error is ${e}`)
        }

        const information = () => {
            if (info) return <InfoOverlay content={info}/>
            else return ""
        }

        const createFields = () => {
            switch (type) {
                case "tel":
                    return <InputMask {...custom} required={required} type={type} onChange={onChange} value={value}
                                      name={name}
                                      mask="(999) 999-9999"
                                      onInvalid={(e) => customValidity(e)}
                                      onKeyUp={(e) => checkValidity(e)}
                                      maskPlaceholder="(___) ___-____"
                                      pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}"
                                      autoComplete="chrome-off"/>
                case "postal_code":
                    return <InputMask {...custom} required={required} type={type} onChange={onChange} value={value}
                                      name={name}
                                      mask="a9a 9a9"
                                      onInvalid={(e) => customValidity(e)}
                                      onKeyUp={(e) => checkValidity(e)}
                                      maskPlaceholder="___ ___"
                                      pattern="[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][ ]?\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]\d"
                                      autoComplete="chrome-off"/>
                case "province":
                    return <div className={"select-container"}>
                        <select onInvalid={(e) => customValidity(e)}
                                onBlur={(e) => checkValidity(e)}
                                ref={selectRef} defaultValue={''} required={required} onChange={onChange} name={name}>
                            <option disabled value="">--</option>
                            <option value="NL">NL</option>
                            <option value="PE">PE</option>
                            <option value="NS">NS</option>
                            <option value="NB">NB</option>
                            <option value="QC">QC</option>
                            <option value="ON">ON</option>
                            <option value="MB">MB</option>
                            <option value="SK">SK</option>
                            <option value="AB">AB</option>
                            <option value="BC">BC</option>
                            <option value="YT">YT</option>
                            <option value="NT">NT</option>
                            <option value="NU">NU</option>
                        </select></div>
                case "credit_card":
                    return <InputMask {...custom} required={required} type="text"
                                      mask={(value && (value.startsWith("34") || value.startsWith("37"))) ? "9999-999999-99999" : "9999-9999-9999-9999"}
                                      onChange={onChange} onInvalid={(e) => customValidity(e)}
                                      onKeyUp={(e) => checkValidity(e)}
                                      value={value} name={name}/>
                default:
                    return <input onInvalid={(e) => customValidity(e)}
                                  onKeyUp={(e) => checkValidity(e)}
                                  {...custom} required={required} type={type} onChange={onChange} value={value}
                                  name={name}/>
            }
        }

        return (
            <span onClick={() => selectRef.current ? selectRef.current.focus() : null}>
                <FormFieldStyle
                    className={className}>
                    {`${label}${required ? "*" : ""}`} {type && (type === 'province') ? <br/> : ""}
                    {information()}
                    {createFields()}
                </FormFieldStyle>
            </span>
        )
    }
}

export default FormField
