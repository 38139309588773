import React from "react"
import styled from "styled-components"
import SiteContext from "~/src/context/Site"
import localization from "~/src/static/localization/common/rental_details/line_items"
import Currency from "./Currency"

const StandardLineStyle = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1em;
    @media (max-width: 768px) {
        grid-template-columns: 3fr 1fr !important;
    }
    label {
        margin:0;
    }
    div:last-child{
        text-align:right;
    }
`

const StandardLineItem = ({type, amount, applied, style, always_show = false}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    let {label, subtext} = type ? localization.line_items[type] : {label: "unknown", subtext: "unknown"}

    return (
        <StandardLineStyle style={{...style}}>
            <div>
                <label>{label}</label> {subtext}
            </div>
            <div>
                <Currency amount={applied || always_show ? amount : undefined}/>
            </div>
        </StandardLineStyle>
    )
}

export default StandardLineItem