import ReactGA from 'react-ga';

export const pageview = (pathname) => {
    if (process.env.DEBUG !== "true") return
    try{
        // Add current path to google anaytics
        ReactGA.pageview("/ero" + pathname);

        console.log(`
            Analytics pageview event:
                /ero${pathname}
        `)
    } catch (e){
        console.log(`Could not record analytics pageview event: ${e}`)
    }
}