import React from "react";
import styled from "styled-components";
import SiteContext from "~/src/context/Site";
import { useLocation } from 'react-router-dom';

const ToggleStyle = styled.div`
    padding: 0;
    border:1px solid #666666;
    border-radius:6px;
    height:100%;
    button {
        display:inline-block;
        padding:4px 16px;
        border-radius:4px;
        margin:0;
        line-height:1;
    }
`;
const selected = {"backgroundColor":"#666", "color":"#fff","border":"1px solid #666666"}
const notselected = {"backgroundColor":"#fff", "color":"#666666", "border":"1px solid #fff"}

const changeButtonColor = (language) => (
    {en:language == "english"?selected:notselected,fr:language != "english"?selected:notselected}
)

const LanguageToggle = () => {
    const {language, setLanguage} = React.useContext(SiteContext)
    let buttonColor = changeButtonColor(language)
    let path = useLocation().pathname
    React.useEffect(() => changeButtonColor(language),[language])

    const handleClick = event => {
        if (process.env.DISABLE_LANGUAGE_TOGGLE == undefined || process.env.DISABLE_LANGUAGE_TOGGLE == 'false') { 
            const value = event.target.value
            setLanguage(value)
        }
    }

    //We don't want to display langugae toggle in certain paths
    switch (path){
        case "/identity_verification": return null;
        default: return (
            <ToggleStyle>
                <button value="english" style={buttonColor.en} onClick={handleClick} >English</button>
                <button value="french" style={buttonColor.fr} onClick={handleClick} >Français</button>
            </ToggleStyle>
        );
    }

}

export default LanguageToggle