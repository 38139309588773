import React from 'react';
import localization from "~/src/static/localization/confirmYourIdentity/main"
import SiteContext from "~/src/context/Site"
import QRCode from 'qrcode.react';
import {RecommendedPopOutStyle} from "./PopOutStyle"
import {Flex,FlexItem} from "./Flex"

const Mobile = ({sessionUrl}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    const labels = localization.option_one

    return (
        <RecommendedPopOutStyle>
            <h2>{labels.header}</h2>
            <Flex>
                <FlexItem
                    width={"50%"}>
                    <p>{labels.desc_one}</p><p>{labels.desc_two}</p>
                </FlexItem>
                <FlexItem width={"30%"}><QRCode value={sessionUrl}/></FlexItem>
            </Flex>
        </RecommendedPopOutStyle>
    )
}

export default Mobile