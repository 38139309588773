import React from "react"
import localization from "~/src/static/localization/common/currency"
import SiteContext from "~/src/context/Site"

const getFormattedAmount = (amount) => {
    let float_amount = parseFloat(amount)
    if (isNaN(float_amount)) float_amount = 0.0
    //Get the sign
    let sign = float_amount >= 0? `` : `-`
    //To two decimal places:
    let string_amount = Math.abs(float_amount).toFixed(2)
    let formattedAmount = `${sign}${string_amount}`
    return formattedAmount
}

const Currency = ({amount="0.00"}) => {
    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)

    let formattedAmount = getFormattedAmount(amount)
    let displayValue = localization.formatString(localization.currency,{
        amount: formattedAmount,
    })
    return (
        <React.Fragment>
            {displayValue}
        </React.Fragment>
    )
}

export default Currency
