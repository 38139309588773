import React from "react";
import styled from "styled-components";
import FormField from "~/src/components/common/FormField";
import SiteContext from '~/src/context/Site';
import localization from "~/src/static/localization/reservation/main";
import {browserErrorMessages} from "~/src/static/localization/validation";
import Switch from "react-switch";
import {FormFieldStyle} from "../../common/FormField";
import CurrencyInput from 'react-currency-input-field';

const AdditionalInformationForm = styled.div`
    margin-top:1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1em;
    grid-template-areas:
        ". . ."
        ". . ."
        ". . .";
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas: none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff;
        color: #666;
        border:1px solid rgba(102,102,102,0.7);
        width:100%;
        border-radius: 4px;
        margin-top: 0.25em;
        padding: 0.3em 2em 0.3em 0.3em;
        font-size: 1em;
    }
    .select-container {
        position:relative; 
        display: inline;
    }
    .select-container:after {
        content: ""; 
        width: 0; 
        height: 0; 
        position: absolute; 
        pointer-events: none;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: .3em;
        right: .75em;
        border-top: 8px solid rgba(102,102,102);
    }
    select::-ms-expand {
        display: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
          -webkit-appearance: none;
           margin: 0;
}
`;
const DoubleField = styled.div`
    display: grid;
    gap: 0.5em;
    grid-template-columns: auto auto;
    align-items: center;
    font-weight:bold;
    color:#666;
`;
const HideInMobile = styled.div`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

const AdditionalInformation = ({onChange,data}) => {

    const {language} = React.useContext(SiteContext)
    localization.setLanguage(language)
    browserErrorMessages.setLanguage(language)
    let labels = localization.additional_information
    const formData = data? data["additional_information"]:{};
    const handleToggle = (checked) => {
        onChange("additional_information",{...formData,include:checked})
    };

    const handleChange = event => {
        let {value,name} = event.target;
        onChange("additional_information",{...formData,[name]:value})
    };

    const onCurrencyChange = (value) => {
        handleChange({
            target:{
                value,
                name:"approximate_value",
            }
        })
    }

    //show custom error on form submit
    const customValidity = (e) => {
        e.target.setCustomValidity(browserErrorMessages.default)
    }

    //update to relevant error as input as user enters data, or else remove error
    const checkValidity = (e) => {
        if (e.target.validity.patternMismatch) {
            e.target.setCustomValidity(browserErrorMessages.wrong_format)
        } else e.target.setCustomValidity('');
    }

    return (
        <AdditionalInformationForm>
            <FormField  label={labels.stored_items_type}>
                <br/>
                <div className="select-container">
                <select required={true} onChange={handleChange} name="stored_items_type">
                    <option value="residential" default>{labels.residential}</option>
                    <option value="commercial">{labels.commercial}</option>
                    <option value="vehicle">{labels.vehicle}</option>
                </select>
                </div>
            </FormField>
            <FormFieldStyle>
                {labels.approx_value}*
                <CurrencyInput onInvalid={(e) => customValidity(e)} onKeyUp={(e) => checkValidity(e)} placeholder={"$ 0.00"} allowDecimals decimalScale={2} prefix={"$ "} onValueChange={onCurrencyChange} required/>
            </FormFieldStyle>
            <HideInMobile />
            <DoubleField>
            {labels.add_company} <Switch uncheckedIcon={false} onChange={handleToggle} checked={formData.include} onColor="#04BE45"/>
            </DoubleField>
            
            {
                formData.include &&
                <FormField  label={labels.company_name}
                            required={true}
                            onChange={handleChange}
                            type="text"
                            formData={formData}
                            name="name"/>
            }
        </AdditionalInformationForm>
    )
}

export default AdditionalInformation