import React from "react";
import { useHistory } from "react-router";
import regeneratorRuntime from "regenerator-runtime";

import Accordion from "react-bootstrap/Accordion";
import AccordionSection from "./accordionSection/Main";
import SiteContext from '~/src/context/Site';
import DocumentContext from '~/src/context/Document'
import {postSubmittedRentalForm} from '~/src/REST'
import {analytics} from "~/src/lib/analytics"

const Main = () => {
    const {authToken,isMoveInSuccessful,setIsMoveInSuccessful} = React.useContext(SiteContext)
    const {contact_data,payment_data,terms_and_conditions,setTerms_and_conditions,pricing,quote} = React.useContext(DocumentContext)
    const history = useHistory();
    const [activeKey, setActiveKey] = React.useState(0);
    const [processMoveIn,setProcessMoveIn] = React.useState(false)
    const [contractTerms,setContractTerms] = React.useState([])

    const submitForm = async (formData) => {
        let response = await postSubmittedRentalForm(formData,authToken)
        setIsMoveInSuccessful(response.status === 200)
    }

    React.useEffect(()=>{
        let temp_terms = []
        if (quote.offer_protection_plan !== false){
            temp_terms.push("user_agreement_protection_plan_offered")
            //Select the apporiate terms
            if (pricing.protection_plan.applied){
                temp_terms.push("certificate_of_protection")
            }else{
                if (contact_data.additional_information.stored_items_type !== "vehicle"){
                    temp_terms.push("protection_plan_exemption_form")
                }
            }
        }else{
            temp_terms.push("user_agreement_protection_plan_not_offered")
        }
        setContractTerms(temp_terms.map((contract_type)=>({contract_type})))
    },[pricing,quote])

    React.useEffect(()=>{
        /*TODO: I hate this implementation but we have a deadline. 
        We need to figure out a better way than forcing a rerender
        to update terms_and_conditions before sending it off.
        */
        //Only trigger on the first time that we submit a move in,
        //For the first time, isMoveInSuccesful should be null and processMoveIn should be true
        if (processMoveIn && isMoveInSuccessful == null) {
            let formData = {
                contact: contact_data,
                payment: payment_data,
                terms_and_conditions,
            }
            submitForm(formData)
            history.push("/reservation/confirmation")
        }
    },[processMoveIn])

    const switchKey = (activeKey,ascending=true) => {
        if (ascending){
            //Submit form
            if (activeKey == (contractTerms.length - 1)) setProcessMoveIn(true)
            else setActiveKey(`${activeKey + 1}`)
        } else {
            //Go to the previous section
            if (activeKey == 0) {
                setTerms_and_conditions([])//Since the user is going back to modify fields we need to wipe all contract ids they have generated
                history.push("/reservation/payment_information")
            }
            //Go to the last contract section
            else setActiveKey(`${activeKey - 1}`)
        }

        analytics.event({
            category: "E-Sign Agreement",
            action: contractTerms[activeKey].contract_type,
            label: (ascending) ? "Next" :"Previous",
        })
    }

    let contractTermComponents = contractTerms.map(({contract_type},index) =>{
        return (
            <AccordionSection   key={index}
                                activeKey={activeKey}
                                index={index}
                                contract_type={contract_type}
                                switchKey={switchKey}
                                submit={index == contractTerms.length - 1}/>
        )
    })

    return (
        <Accordion activeKey={`${activeKey}`} className="container">
            {contractTermComponents}
        </Accordion>
    )
}


export default Main